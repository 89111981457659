import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ConstituencyFinder, FormAnchor, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

import MPs from '../MPs.json'

const PUBLIC_URL = process.env.PUBLIC_URL || "https://community.peoplesmomentum.com"

// Page content (this could probably be pushed out into another file)

const stopSigns = ['⛔', '🚫']

const emojify = (subjects, emoji) =>
  subjects.concat(...emoji.map(e => subjects.map(s => `${e} ${s} ${e}`)))

const subjects = emojify([
  `Oppose the Rwanda deportation plan`,
  `Advocate a safe passage for refugees`,
  `End deportations now`,
  `Stop the demonisation of migrants`,
], stopSigns)

const content = {
  // Page
  title: `No to barbaric Rwanda deportations!`,
  intro: `The Tories have announced a barbaric plan to deport refugees crossing the Channel to Rwanda. These are vulnerable people making perilous journeys in search of a more secure life. But instead of welcoming them, the Government is planning to send them thousands of miles away, to a country they have never been to before. It’s the latest attack on the rights of people seeking asylum, denied safe routes and then punished for simply seeking refuge.

Use our lobbying tool to demand your MP speaks out against this racist Rwanda plan. Refugees are welcome here.`,

  // Email
  recipients: (vs: Values) => vs.MP.Email,
  subject: subjects,
  header: (vs: Values) => `Dear ${vs.MP ? vs.MP['Name (Display as)'] : 'MP'},`,
  body: `The Rwanda deportation plan is barbaric and shameful - it must be scrapped.

Refugees should be welcomed and supported, not criminalised and deported. I was horrified to read of the Government’s plans to send vulnerable people seeking asylum to camps thousands of miles away. The fear and hatred of migrants whipped up by the right-wing newspapers doesn’t represent me, or the people of this country.

As one of your constituents, I am asking you to oppose this racist plan and instead advocate safe passage for refugees coming to Britain.`,
  footer: (vs: Values) => [
    'Yours,',
    `${vs.givenName} ${vs.familyName}`.length > 1
      ? `${vs.givenName} ${vs.familyName}`
      : 'A concerned constituent',
  ].join('\n\n'),
  share: `I just told my MP to oppose the racist Rwanda deportations! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Vimeo,
  urlOrSlug: 'https://player.vimeo.com/video/711990549?h=f5f699af42',

  // NB
  eventCode: '2022-05 Lobby Rwanda',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/lobby?rwanda`,
  description: `Use this to tell your MP to end the barbaric Rwanda deportations!`,
  img: `${PUBLIC_URL}/img/govt-is-racist-2-1.jpg`,
  imgAlt: `The British government is racist`,
}

const Rwanda = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          bame: undefined,
          constituency: undefined,
          MP: undefined,
          body: content.body,
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <h1>{content.title}</h1>

                  <ReactMarkdown>{content.intro}</ReactMarkdown>
 
                  <SingleLineField
                    property="postcode"
                    label="First, enter your UK postcode"
                    errors={errors}
                  />
                  <ConstituencyFinder mps={MPs} body={content.body} />
 
                  { values.constituency && (
                    <>
                      <SingleLineField
                        property="givenName"
                        label="First name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="familyName"
                        label="Last name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="email"
                        label="Email address"
                        errors={errors}
                        type="email"
                        required
                      />
                      <SingleLineField
                        property="mobile"
                        label="Mobile phone"
                        errors={errors}
                      />
                      <OptionField
                        property="labour"
                        label="Are you a Labour Party member?"
                        options={["--", "Yes", "No"]}
                        errors={errors}
                      />
                      <OptionField
                        property="bame"
                        label="Do you consider yourself to be an ethnic minority?"
                        options={["--", "Yes", "No"]}
                        errors={errors}
                      />
                      {
                        // <OptionField property="union" label="Which union are you in?" options={["--", ...unions]} errors={errors} />
                      }
                    </>
                  )}
                  <FormAnchor />
                </Col>
              </Row>
            </Container>
 
            { values.constituency && (
              <>
                <div className="bg-neutral-200">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="12" md="6">
                        <h2>Edit your email</h2>

                        <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                        <EmailBodyField property="body" errors={errors} />

                        <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                      </Col>
                    </Row>
                  </Container>
                </div>
 
                <Container>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      <LobbyNotice
                        errors={errors}
                        submissionStatus={submissionStatus}
                      />

                      <OptInField property="optIn" errors={errors} />

                      <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                    </Col>
                  </Row>
                </Container>
              </>
            ) }

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName={values.MP && (values.MP as any)['Name (Display as)']}
              copy={content.share}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default Rwanda
