import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  ThanksPageRedirect, PetitionSubmitButton, PetitionNotice, SignatureCount
} from '../Common/PetitionComponents'
import { handleSubmit } from '../Common/PetitionUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)
const content = {
  // Page
  title: `Labour: Protect Equality Roles!`,
  intro: `*On the 26th September the Labour National Executive Committee passed proposals to attack the democratic rights of party members from minority communities. Add your name to this statement and let's build pressure to reject these proposals ahead of a crucial vote at Labour Conference.*

The National Executive Committee has put forward proposals to Labour Conference that are a direct attack on party democracy. They include **a new rule change that abolishes mandatory equality roles** for disabled, BAME and LGBT+ members on CLP executive committees.

This comes after the downgrading of Labour Women's Conference and the scrapping of agreed democratic structures for BAME members, representing an attack on the rights of Labour activists from minority communities to organise and make their voices heard.

Members are the lifeblood of our party. **We must stop this - and we can!** To come into force, the rule change needs to be passed at Labour Conference on Sunday 8th October. We urge all Labour Party stakeholders to **reject this rule change** on the Conference floor and stand up for equal rights in the Labour Party.`,

  // Share
  share: (vs: Values) => `I just demanded that Labour protect equality roles! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/petition_nec_banner.png',

  // NB
  eventCode: '2023-09 Petition NEC',

  // Petition ID
  petitionId: 2,
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/petition?lobby`,
  description: `Sign our petition to demand Labour protect equality roles!`,
  img: `${PUBLIC_URL}/img/petition_nec_banner.png`,
  imgAlt: `Labour: Protect Equality Roles!`,
}

const EqualityRoles = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SignatureCount petitionId={content.petitionId} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <PetitionNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <PetitionSubmitButton submissionStatus={submissionStatus} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default EqualityRoles
