import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ConstituencyFinder, FormAnchor, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

import MPs from '../MPs.json'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// MPs that have actually been on a picket line.
// Names have to be as they appear in MPs.json, which means including honorifics...
// NOTE: shouldEmail returns false for non-Labour MPs, so there's no point adding Corbyn in this array
// Instead, I've hardcoded him to show up as supporting
// NOTE: I'm repurposing the values to be true/false depending on if they voted.
// If they're in here and didn't vote then comms has decided they would've voted for a ceasefire.
const ceasefireSupporters = {
  'Ms Diane Abbott': true,
  'Tahir Ali': true,
  'Dr Rosena Allin-Khan': true,
  'Paula Barker': true,
  'Apsana Begum': true,
  'Mr Clive Betts': true,
  'Olivia Blake': false,
  'Paul Blomfield': true,
  'Ms Karen Buck': true,
  'Richard Burgon': true,
  'Dawn Butler': true,
  'Liam Byrne': true,
  'Ian Byrne': true,
  'Dan Carden': true,
  'Sarah Champion': true,
  'Stella Creasy': true,
  'Jon Cruddas': true,
  'Judith Cummins': true,
  'Marsha De Cordova': true,
  'Peter Dowd': true,
  'Julie Elliott': true,
  'Mary Kelly Foy': true,
  'Barry Gardiner': true,
  'Margaret Greenwood': true,
  'Fabian Hamilton': true,
  'Kate Hollern': true,
  'Rachel Hopkins': true,
  'Dr Rupa Huq': true,
  'Imran Hussain': true,
  'Kim Johnson': false,
  'Afzal Khan': true,
  'Ian Lavery': true,
  'Mrs Emma Lewell-Buck': true,
  'Clive Lewis': true,
  'Rebecca Long Bailey': true,
  'Mr Khalid Mahmood': true,
  'Rachael Maskell': true,
  'Andy McDonald': true,
  'John McDonnell': true,
  'Ian Mearns': true,
  'Grahame Morris': true,
  'Kate Osamor': true,
  'Kate Osborne': true,
  'Sarah Owen': true,
  'Jess Phillips': true,
  'Yasmin Qureshi': true,
  'Bell Ribeiro-Addy': true,
  'Lloyd Russell-Moyle': true,
  'Naz Shah': true,
  'Andy Slaughter': true,
  'Cat Smith': true,
  'Alex Sobel': true,
  'Zarah Sultana': true,
  'Sam Tarry': true,
  'Stephen Timms': true,
  'Jon Trickett': true,
  'Valerie Vaz': true,
  'Mick Whitley': false,
  'Nadia Whittome': true,
  'Beth Winter': true,
  'Mohammad Yasin': true,
}

// Page content (this could probably be pushed out into another file)

// Email bodies
// This one is for MPs who've not spoken up yet
const emailForMP = `I am deeply alarmed by what is happening in Gaza. There can be no justification for the indiscriminate slaughter being carried out by Israel. I note with concern that the International Court of Justice ordered Israel to cease all activities that constitute to genocide, but that a UN expert explained how the order has been breached already.

It is shameful that the UK Government has not called out for a full and immediate ceasefire. But I am equally disappointed that the Labour opposition has followed their lead, rather than standing up for human rights.

With Parliament voting again this week, we need all politicians to speak up for an urgent ceasefire, to prevent yet more bloodshed and focus on saving lives. The Labour Party prides itself on being an internationalist party - it's time to live up to that legacy. I urge you to publicly call for an immediate ceasefire.`

// And this one is for people who's MP has spoken up, or isn't a Labour MP
const emailForKeith = `I am deeply alarmed by what is happening in Gaza. There can be no justification for the indiscriminate slaughter being carried out by Israel. I note with concern that the International Court of Justice ordered Israel to cease all activities that constitute to genocide, but that a UN expert explained how the order has been breached already.

It is shameful that the UK Government has not called out for a full and immediate ceasefire. But I am equally disappointed in your failure to stand up for human rights, instead following the Tory line. By refusing to back a full ceasefire, and instead only backing a conditional, 'sustainable' ceasefire, you are making Labour complicit.

With Parliament voting again this week, we need all politicians to speak up for an urgent ceasefire, to prevent yet more bloodshed and focus on saving lives. The Labour Party prides itself on being an internationalist party - it's time to live up to that legacy. I urge you to publicly call for an immediate ceasefire.`

// If the MP is in the Labour Party and not in the list of supporters, we should email them
// Otherwise email Keith directly
const shouldEmail = (MP: any) => {
  const isLabour = (MP && MP['Party'] === 'Labour')
  const notStrikeSupporter = !Object.keys(ceasefireSupporters).includes((MP?.['Name (Display as)'] as string))
  return isLabour && notStrikeSupporter
}

// Returns the relevant email body for the given MP
const getEmailBodyForMP = (MP: any) => {
  if (shouldEmail(MP)) {
    return emailForMP
  } else {
    return emailForKeith
  }
}

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Kind regards,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (shouldEmail(vs.MP)) {
    return [message, 'A concerned constituent'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

const subjects = [
  'Speak Up for a Ceasefire!',
  'Labour Must Back a Ceasefire',
  'Ceasefire Now!',
]

const content = {
  // Page
  title: `Labour Speak Up: Ceasefire Now!`,
  intro: `***Updated 18th February 2024***

Israel continues to commit countless war crimes in Gaza, from besieging hospitals to deadly strikes wiping out entire families. Tens of thousands of children have been killed.  Just days ago, the International Court of Justice ruled that this could plausibly constitute a genocide and ordered Israel to stop the killing.

Longer term, we need an end to apartheid and arms sales to Israel. Right now, we must stop the bloodshed, and with Israel launching deadly operations in Rafah, the urgency is greater still. That must mean a full and immediate ceasefire. But the Labour Leadership is refusing to back one. In November, 10 shadow frontbenchers resigned and 56 Labour MPs rebelled against Starmer's whip to back a full ceasefire. On Wednesday, the House of Commons votes again.

The situation in Gaza right now is unbearable - there can be no excuses. Use our lobbying tool to demand your Labour MP votes for a full ceasefire. If your Labour MP already voted for a ceasefire or you don't have a Labour MP - you can email Keir Starmer and David Lammy instead.`,

  // Email
  recipients: (vs: Values) => shouldEmail(vs.MP) ? vs.MP.Email : ['keir.starmer.mp@parliament.uk', 'lammyd@parliament.uk'],
  subject: (vs: Values) => subjects,
  header: (vs: Values) => `Dear ${vs.MP && shouldEmail(vs.MP) ? vs.MP['Name (Display as)'] : 'Keir Starmer and David Lammy'},`,
  body: getEmailBodyForMP,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I'm demanding that ${shouldEmail(vs.MP) ? 'my MP' : 'Keir Starmer and David Lammy'} call for a ceasefire! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/lobby_ceasefire.png',

  // NB
  eventCode: '2023-10 Lobby Palestine',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/palestine?lobby`,
  description: `Use this to demand that your MP calls for a ceasefire!`,
  img: `${PUBLIC_URL}/img/lobby_ceasefire.png`,
  imgAlt: `Labour Speak Up: Ceasefire Now!`,
}

const messageText = (MP: any) => {
  const mpName = MP?.['Name (Display as)']
  if (mpName === 'Jeremy Corbyn') {
    return (
      <>
        Your MP is Jeremy Corbyn -&nbsp;
        Our research shows that your MP did vote for a ceasefire in November. Use our lobbying tool below to demand Keir Starmer and David Lammy support a full and immediate ceasefire in Gaza.
      </>
    )
  } else if (shouldEmail(MP)) {
    return `Your Labour MP is ${MP?.['Name (Display as)']} - Our research shows that your Labour MP did not vote for a ceasefire in November. Use our lobbying tool below to demand they vote for a full and immediate ceasefire in Gaza this week.`
  } else {
    return (
      <>
        {
          MP?.['Party'] === 'Labour' ? (
            <>
              Your Labour MP is {MP?.['Name (Display as)']} -&nbsp;
              {
                ceasefireSupporters[MP?.['Name (Display as)']] ?
                  <>
                    Our research shows that your Labour MP did vote for a ceasefire in November. Use our lobbying tool below to demand Keir Starmer and David Lammy support a full and immediate ceasefire in Gaza.
                  </>
                  :
                  <>
                    Our research shows that your Labour MP publicly backed the ceasefire amendment in November, though they were away for the vote. Use our lobbying tool below to demand Keir Starmer and David Lammy support a full and immediate ceasefire in Gaza.
                  </>
              }
            </>
          ) : `You don't have a Labour MP. Use our lobbying tool below to demand Keir Starmer and David Lammy support a full and immediate ceasefire in Gaza.`
        }
      </>
    )
  }
}

const Palestine = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <h1>{content.title}</h1>

                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SingleLineField
                    property="postcode"
                    label="First, enter your UK postcode"
                    errors={errors}
                  />
                  <ConstituencyFinder mps={MPs} body={content.body} />

                  { values.constituency && (
                    <>
                      <div style={{marginBottom: '1rem'}}>
                        { messageText(values?.MP) }
                      </div>
                      <SingleLineField
                        property="givenName"
                        label="First name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="familyName"
                        label="Last name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="email"
                        label="Email address"
                        errors={errors}
                        type="email"
                        required
                      />
                      <SingleLineField
                        property="mobile"
                        label="Mobile phone"
                        errors={errors}
                      />
                      <OptionField
                        property="labour"
                        label="Are you a Labour Party member?"
                        options={['--', 'Yes', 'No']}
                        errors={errors}
                      />
                    </>
                  )}
                  <FormAnchor />
                </Col>
              </Row>
            </Container>

            { values.constituency && (
              <>
                <div className="bg-neutral-200">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="12" md="6">
                        <h2>Edit your email</h2>

                        <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                        <EmailBodyField property="body" errors={errors} />

                        <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <Container>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      <LobbyNotice
                        errors={errors}
                        submissionStatus={submissionStatus}
                      />

                      <OptInField property="optIn" errors={errors} />

                      <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                    </Col>
                  </Row>
                </Container>

                <Container style={{marginTop: 0, marginBottom: 15}}>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      * Has your MP replied to you declaring that they're already backing calls for a ceasefire? Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                    </Col>
                  </Row>
                </Container>
              </>
            ) }

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName={
                values.MP && shouldEmail(values.MP) ? (values.MP as any)['Name (Display as)'] : 'Keir Starmer and David Lammy'
              }
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default Palestine
