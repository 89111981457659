import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ProseLoader, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Folks to send the emails to
const recipients = [
  'akehurstluke@googlemail.com',
  'johanna.baxter@btinternet.com',
  'gurinder_singh_josan@hotmail.com',
  'abdi4nec@gmail.com',
  'keir.starmer.mp@parliament.uk',
  'angela.rayner.mp@parliament.uk',
  'anneliese.dodds.mp@parliament.uk',
  'james.asser@yahoo.co.uk',
  'david_evans@labour.org.uk',
  'c.sewell@unison.co.uk',
]

// Page content (this could probably be pushed out into another file)

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Yours in solidarity,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

const emailSubjects = [
  'Don\'t scrap BAME structures',
  'Don\'t disenfranchise BAME members',
  'Stand with BAME members tomorrow',
  'Keep your promise: implement BAME structures',
]

const content = {
  // Page
  title: `Demand democratic structures for BAME Labour members`,
  intro: `In 2018, Labour's Democracy Review proposed the creation of new democratic structures for BAME Labour members, including a Conference and National Committee. Recognising the Party's historic failure to represent BAME members, these structures were confirmed first by Conference and then by the NEC last year, after which they were incorporated into the Party's rulebook.

But now, the Labour Leadership is trying to row back by indefinitely postponing these structures, instead reviving the widely discredited socialist society BAME Labour, which has no real democracy and which requires payment to join. Equally, the promised democratic Disabled Structures are now also at risk.

We can't let the Leadership overturn the Party's own rulebook and disenfranchise tens of thousands of BAME Labour members. After the Forde Report and repeated scandals on anti-Black racism and Islamophobia, BAME Labour members need real representation. Write to Keir Starmer, David Evans, BAME rep Carol Sewell and other NEC members now to demand that they vote down this proposal and implement in full the agreed democratic BAME structures!`,

  // Email
  recipients: recipients,
  subject: emailSubjects,
  header: (vs: Values) => `Dear NEC reps,`,
  body: `It is with deep concern that I have read that the Labour Party leadership is planning to cancel the implementation of a Conference and National Committee for Labour's BAME members, in favour of reviving the dysfunctional and undemocratic BAME Labour affiliate.

The Party took significant and much-needed steps on BAME representation with the promise, agreed by the NEC as recently as last year, to set up democratic BAME structures, including a National Committee and Conference. It would be deeply damaging to relations with Labour's BAME members and indeed BAME communities across the country if Labour were to undo this agreement and effectively disenfranchise thousands of its own BAME members.

In the wake of recent scandals around anti-black racism and Islamophobia, confirmed by the Forde Report, it's more important than ever that BAME Labour members are able to organise democratically and be heard within the Party. Engagement events are no replacement for real representation.

BAME Labour is not up to this task either. It is a widely discredited organisation, which has demonstrably failed to represent BAME members, with just 731 ballots cast in its 2017 elections, despite there being tens of thousands of BAME members within Labour. What's more, it still requires a payment to join, meaning that BAME Labour members would, outrageously, have to pay again for democratic representation.

On Tuesday, I hope you will stand with the thousands of BAME members of the Labour Party who fought hard to ensure their representation at the highest levels of the Party is democratic, transparent and accountable – and vote for the full implementation of Labour's democratic BAME structures, as laid out in the Party rulebook.`,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just told NEC reps to demand real democratic structures for BAME Labour members! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.None,

  // NB
  eventCode: '2022-11 Lobby BAME Labour',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/bame-labour?lobby`,
  description: `Use this to tell your MP to demand Labour creates real democratic structures for BAME members!`,
  img: '',
  imgAlt: `Labour must create real democratic BAME structures`,
}

const BAMELabour = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          bame: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <h1>{content.title}</h1>

                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <ProseLoader body={content.body} />
                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <OptionField
                    property="bame"
                    label="Do you consider yourself to be an ethnic minority?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>

            <div className="bg-neutral-200">
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" md="6">
                    <h2>Edit your email</h2>

                    <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                    <EmailBodyField property="body" errors={errors} />

                    <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <LobbyNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName="the NEC reps"
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default BAMELabour
