import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ConstituencyFinder, FormAnchor, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

import MPs from '../MPs.json'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// MPs that have actually been on a picket line.
// Names have to be as they appear in MPs.json, which means including honorifics...
// NOTE: shouldEmail returns false for non-Labour MPs, so there's no point adding Corbyn in this array
// Instead, I've hardcoded him to show up as supporting
const strikeSupporters = [
  'Dame Angela Eagle',
  'Kate Green',
  'Dr Rupa Huq',
  'Mrs Emma Lewell-Buck',
  'Tony Lloyd',
  'Lisa Nandy',
  'Mohammad Yasin',
  'Geraint Davies',
  'Ruth Jones',
  'Ms Diane Abbott',
  'Tahir Ali',
  'Mike Amesbury',
  'Paula Barker',
  'Apsana Begum',
  'Olivia Blake',
  'Richard Burgon',
  'Dawn Butler',
  'Ian Byrne',
  'Dan Carden',
  'Peter Dowd',
  'Mary Kelly Foy',
  'Margaret Greenwood',
  'Kate Hollern',
  'Imran Hussain',
  'Kim Johnson',
  'Ian Lavery',
  'Clive Lewis',
  'Rebecca Long Bailey',
  'Andy McDonald',
  'John McDonnell',
  'Ian Mearns',
  'Navendu Mishra',
  'Kate Osborne',
  'Bell Ribeiro-Addy',
  'Zarah Sultana',
  'Sam Tarry',
  'Jon Trickett',
  'Mick Whitley',
  'Nadia Whittome',
  'Beth Winter',
  'Rachael Maskell',
  'Lloyd Russell-Moyle',
  'Charlotte Nichols',
  'Cat Smith',
  'Karl Turner',
]

// Page content (this could probably be pushed out into another file)

// Email bodies - I've moved these out cos the content object definition was getting straight up unreadable
// This one is for MPs who've not been on the picket line yet
const emailForMP = `Millions of us are struggling in a cost-of-living crisis - with inflation at 12%, energy bills soaring and wages lagging behind, we just don't have enough money in our pockets.

So over the past few weeks, I've been inspired by the hundreds of thousands of workers across the country standing up to protect their livelihoods - from rail workers in the RMT and ASLEF, to postal and BT workers in the CWU, and Amazon workers staging walkouts in response to the insulting offer of a 35p pay rise.

It isn't right that workers now face real-terms cuts to their pay, terms and conditions. Given the Tories are shamefully abandoning the British people to poverty, we need the Labour Party to stand up for working people.

As one of your constituents, I am asking you to support striking workers, and attend a picket line in solidarity.

I am requesting a meeting with you to discuss this, and would like you to confirm to me, yes or no, whether you support these strikes and will be attending a picket line during upcoming strike actions?

I look forward to receiving your response.`

// This one is for constituents of MPs who have been on the picket lines
const emailForKeith = `Millions of us are struggling in a cost-of-living crisis - with inflation at 12%, energy bills soaring and wages lagging behind, we just don't have enough money in our pockets.

So over the past few weeks, I've been inspired by the hundreds of thousands of workers across the country standing up to protect their livelihoods - from rail workers in the RMT and ASLEF, to postal and BT workers in the CWU, and Amazon workers staging walkouts in response to the insulting offer of a 35p pay rise.

It isn't right that workers now face real-terms cuts to their pay, terms and conditions. Given the Tories are shamefully abandoning the British people to poverty, we need the Labour Party to stand up for working people.

I was dismayed by the recent sacking of shadow minister Sam Tarry over attending a picket line and advocating pay rise in line with inflation. This kind of behaviour is not in keeping with Labour’s values or historic traditions. We need solidarity, not sackings.

I am asking you to keep to your pledges and to stand with our trade unions in their hour of need, by publicly supporting the strikes and dropping the ban on shadow ministers attending picket lines.`

// And this one's for Keith's constituents
const emailForKeithFromConstituent = `Millions of us are struggling in a cost-of-living crisis - with inflation at 12%, energy bills soaring and wages lagging behind, we just don't have enough money in our pockets.

So over the past few weeks, I've been inspired by the hundreds of thousands of workers across the country standing up to protect their livelihoods - from rail workers in the RMT and ASLEF, to postal and BT workers in the CWU, and Amazon workers staging walkouts in response to the insulting offer of a 35p pay rise.

It isn't right that workers now face real-terms cuts to their pay, terms and conditions. Given the Tories are shamefully abandoning the British people to poverty, we need the Labour Party to stand up for working people.

I was dismayed by the recent sacking of shadow minister Sam Tarry over attending a picket line and advocating pay rise in line with inflation. This kind of behaviour is not in keeping with Labour’s values or historic traditions. We need solidarity, not sackings.

As one of your constituents, I am asking you to keep to the pledges you made during your leadership campaign, and to stand with our trade unions in their hour of need, by publicly supporting the strikes and dropping the ban on shadow ministers attending picket lines.

I am requesting a meeting with you to discuss this, and would like you to confirm to me, yes or no, whether you support these strikes and will be attending a picket line during upcoming strike actions?`

// If the MP is in the Labour Party and not in the list of supporters, we should email them
// Otherwise email Keith directly
const shouldEmail = (MP: any) => {
  const isLabour = (MP && MP['Party'] === 'Labour')
  const notStrikeSupporter = !strikeSupporters.includes((MP?.['Name (Display as)'] as string))
  return isLabour && notStrikeSupporter
}

// Returns the relevant email body for the given MP
const getEmailBodyForMP = (MP: any) => {
  if (MP['Name (Display as)'] === 'Keir Starmer') {
    return emailForKeithFromConstituent
  } else if (shouldEmail(MP)) {
    return emailForMP
  } else {
    return emailForKeith
  }
}

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = shouldEmail(vs.MP) ? 'Yours in solidarity,' : 'Best wishes,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else {
    return [message, 'A concerned constituent'].join('\n\n')
  }
}

const subjectsForMP = [
  'Are you supporting striking workers?',
  'Support the workers!',
  'Join a picket line - show your support',
  'Back working people',
]

const subjectsForKeith = [
  'Back working people',
  'Support the workers!',
]

const content = {
  // Page
  title: `Labour MPs: Stand with Workers`,
  intro: `Britain is in crisis. Energy bills are already unaffordable and still rising, while every pound in workers' pockets is being devalued by soaring inflation.

As the out-of-touch Tory leadership contenders compete to offer tax cuts for the richest, Labour has the opportunity to show it's on the side of working people by backing workers striking to protect themselves and their right to an inflation-proof payrise.

But by banning shadow ministers from picket lines, Keir Starmer risks driving a wedge between Labour and the millions of working people desperate for real relief in the cost of living crisis.

It's time to unite as a labour movement and send a clear message: we back the workers.

Email your Labour MP now using our lobbying tool to demand they support the strikes, on the picket lines and in Parliament.`,

  // Email
  recipients: (vs: Values) => shouldEmail(vs.MP) ? vs.MP.Email : 'keir.starmer.mp@parliament.uk',
  subject: (vs: Values) => shouldEmail(vs.MP) ? subjectsForMP : subjectsForKeith,
  header: (vs: Values) => `Dear ${vs.MP && shouldEmail(vs.MP) ? vs.MP['Name (Display as)'] : 'Keir Starmer'},`,
  body: getEmailBodyForMP,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just told ${shouldEmail(vs.MP) ? 'my MP' : 'Keir Starmer'} to support workers! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.YouTube,
  urlOrSlug: 'tuFn0Se2zxM',

  // NB
  eventCode: '2022-08 Lobby L4L',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/labour-for-labour?lobby`,
  description: `Use this to tell your MP to support the workers!`,
  img: `${PUBLIC_URL}/img/L_for_l_Tw_event.png`,
  imgAlt: `Labour MPs should support the workers`,
}

const messageText = (MP: any) => {
  const mpName = MP?.['Name (Display as)']
  if (mpName === 'Apsana Begum') {
    return (
      <>
        Apsana Begum is currently on sick leave following a concerted campaign of misogynistic abuse. She has previously supported workers on strike.* Solidarity.
        <br />
        <br />
        Use our lobbying tool below to demand Keir Starmer publicly supports workers, and drops his outrageous ban on shadow ministers attending picket lines.
      </>
    )
  } else if (mpName === 'Jeremy Corbyn') {
    return 'Jeremy Corbyn has already shown support for striking workers by joining them on the picket lines.* Use our lobbying tool below to demand Keir Starmer publicly supports workers, and drops his outrageous ban on shadow ministers attending picket lines.'
  } else if (shouldEmail(MP)) {
    return `${MP?.['Name (Display as)']} has not yet supported workers by joining them on the picket lines.* Use our lobbying tool below to demand your Labour MP commits to supporting the striking workers by attending a picket.`
  } else {
    return `${MP?.['Party'] === 'Labour' ? `${MP?.['Name (Display as)']} has already shown support for striking workers by joining them on the picket lines.* ` : ''}Use our lobbying tool below to demand Keir Starmer publicly supports the strike, and drops his outrageous ban on shadow ministers attending picket lines.`
  }
}

const L4L = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          bame: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <h1>{content.title}</h1>

                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SingleLineField
                    property="postcode"
                    label="First, enter your UK postcode"
                    errors={errors}
                  />
                  <ConstituencyFinder mps={MPs} body={content.body} />

                  { values.constituency && (
                    <>
                      {
                        messageText(values?.MP)
                      }
                      <SingleLineField
                        property="givenName"
                        label="First name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="familyName"
                        label="Last name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="email"
                        label="Email address"
                        errors={errors}
                        type="email"
                        required
                      />
                      <SingleLineField
                        property="mobile"
                        label="Mobile phone"
                        errors={errors}
                      />
                      <OptionField
                        property="labour"
                        label="Are you a Labour Party member?"
                        options={['--', 'Yes', 'No']}
                        errors={errors}
                      />
                      <OptionField
                        property="bame"
                        label="Do you consider yourself to be an ethnic minority?"
                        options={['--', 'Yes', 'No']}
                        errors={errors}
                      />
                    </>
                  )}
                  <FormAnchor />
                </Col>
              </Row>
            </Container>

            { values.constituency && (
              <>
                <div className="bg-neutral-200">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="12" md="6">
                        <h2>Edit your email</h2>

                        <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                        <EmailBodyField property="body" errors={errors} />

                        <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <Container>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      <LobbyNotice
                        errors={errors}
                        submissionStatus={submissionStatus}
                      />

                      <OptInField property="optIn" errors={errors} />

                      <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                    </Col>
                  </Row>
                </Container>

                <Container style={{marginTop: 0, marginBottom: 15}}>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      * Has your MP replied to you declaring that they've already been out on the picket lines? Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                    </Col>
                  </Row>
                </Container>
              </>
            ) }

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName={
                values.MP && shouldEmail(values.MP) ? (values.MP as any)['Name (Display as)'] : 'Keir Starmer'
              }
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default L4L
