// TODO: Should I not have a file specifically for defining types somewhere?
enum SubmissionStatus {
  Unsubmitted,
  Submitting,
  Success,
  Failure,
}

enum MediaType {
  None,
  Image,
  YouTube,
  Vimeo,
}

interface Values {
  // union: string
  givenName: string
  familyName: string
  email: string
  mobile: string
  postcode?: string
  optIn: boolean
  labour?: '--' | 'Yes' | 'No'
  bame?: '--' | 'Yes' | 'No'
  constituency?: string
  MP?: any
  body: string
}

// Regex for validating UK postcodes
// TODO: Review this - we've gotten reports of it not allowing some valid postcodes
// (although I've not been given any examples of these failing postcodes yet,)
// TODO: Is there any way to break this up so it doesn't wrap...?
const postcodeRegex = /^(([A-Z]{1,2}[0-9][A-Z0-9]?|ASCN|STHL|TDCU|BBND|[BFS]IQQ|PCRN|TKCA) ?[0-9][A-Z]{2}|BFPO ?[0-9]{1,4}|(KY[0-9]|MSR|VG|AI)[ -]?[0-9]{4}|[A-Z]{2} ?[0-9]{2}|GE ?CX|GIR ?0A{2}|SAN ?TA1)$/

// Convert "Yes"/"No"/"--" values to true/false/undefined
const yesNoToBool = (value: '--' | 'Yes' | 'No' | undefined) => {
  return value === undefined ? undefined : { Yes: true, No: false }[value]
}

// Send telemetry data to plausible
// NOTE: following the extensive form changes, this probably needs significant adjustment
const postTelemetry = (vs: Values, eventCode: string) => {
  const decodeTriValue = (x: string|undefined) => x === undefined || x === '--' ? 'unknown' : x
  window.plausible && window.plausible(
    'lobby',
    {
      props: {
        event: eventCode,
        'is labour member': decodeTriValue(vs.labour),
        'is ethnic minority': decodeTriValue(vs.bame),
        // 'union': vs.union,
      }
    }
  )
}

// NOTE: If I try to export this with everything else the linter yells at me,
// The weird part is, it cites TS1205, which is for *re*-exporting types... I guess it's just bad wording?
export type { Values }
export { SubmissionStatus, MediaType, postcodeRegex, postTelemetry, yesNoToBool }
