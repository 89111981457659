import { SubmitForm } from '../../../components/FormGroup/FormGroup'
import { SubmissionStatus, Values, postTelemetry, yesNoToBool } from '../../../components/Common/CommonUtils'

// Convert form values to the format the backend wants and send it over,
// then post telemetry if successful, and set the submission status
const handleSubmit = (setSubmissionStatus, content) => async (values: Values) => {
  // I know Formik has its isSubmitting prop but this way I can just use a switch statement
  setSubmissionStatus(SubmissionStatus.Submitting)
  const convertedValues = { petition: {
    signature: {
      email: values.email,
      consents: {
        general: values.optIn ? {
          reason: content.eventCode + ' signup',
          email: true,
          call: true,
          text: true,
          post: true,
          social: true,
        } : undefined
      },
      givenName: values.givenName,
      familyName: values.familyName,
      mobile: values.mobile,
      postcode: values.postcode,
      public: false,
      labour: yesNoToBool(values.labour),
      tags: [content.eventCode],
    },
  } }

  // Send to backend
  const res = await SubmitForm(`/signpetition/${content.petitionId}`, convertedValues)

  if (res?.ok) {
    postTelemetry(values, content.eventCode)
    setSubmissionStatus(SubmissionStatus.Success)
  } else if (res?.status === 422) {
    // If we got a 422 here it means the user already signed the petition
    // Reporting this would involve a bunch of changes so for now just pretend it all went fine
    setSubmissionStatus(SubmissionStatus.Success)
  } else {
    setSubmissionStatus(SubmissionStatus.Failure)
  }
}

export { handleSubmit }
