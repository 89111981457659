import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, FormGroup} from 'reactstrap'
import * as Yup from 'yup'
import ReactMarkdown from 'react-markdown'
import { SingleLineField, CheckBoxField, SubmitForm } from '../../components/FormGroup/FormGroup'

import './Volunteer.scss'
import { Formik, Form } from 'formik'
import Iframe from 'react-iframe'

import { Imprint } from '../../components/Common/CommonComponents'

const introPara = `
# Join Momentum Community, Our Online Discussion Space

Led by Momentum activists, and for anyone hoping to build a better world, this is a space for us to meet one another, discuss politics and strategy, and get involved in projects to build socialism - from media reform reading groups to grassroots strike solidarity. Everyone is welcome, regardless of previous experience or involvement levels.

Momentum Community isn’t just for organising - we also hope this will become a place where you can socialise and relax with other Momentum members, and others committed to transformative politics.

Current projects being run here include:

-   Lunchtime skillshare sessions
-   A solidarity ring-round project to build connections and combat isolation
-   Political discussion channels
-   Online political education sessions

Current discussions and planning now happening include:

-   Momentum’s new strategy document - Socialist Organising in a New Era
-   The Momentum Trade Unionists Network
-   Political education and campaigns
-   Momentum’s Eviction Resistance campaign

You can also participate in the next phase of Refounding Momentum (launching mid June) via Momentum Community as well organise your own projects with Momentum members and supporters across the country. So get started!

Our online community space runs on Slack - access our Slack workspace through your internet browser or, for extra features, download the free Slack app here: 

- [Windows](https://slack.com/intl/en-gb/downloads/windows)
- [Mac](https://slack.com/intl/en-gb/downloads/mac)
- [Android](https://slack.com/intl/en-gb/downloads/android) / [iOS](https://slack.com/intl/en-gb/downloads/ios)

[Read our Community Guidelines here](https://docs.google.com/document/d/1fhHkMcY7RKsFictJW0QiI6MazHGGGqyJ6Np4vF8a6T4/edit).
`

const codeOfConductPara = `
# A few house rules on our Slack
## We're in this new community together. We’ll help each other and treat each other with respect.
Any posts that are perceived as unkind or disrespectful will be removed, and in certain cases the poster may be asked to leave the workspace as well.
## We’ll escalate concerns directly to staff.
If you have any concerns about something that’s going on in Slack, please escalate to a staff member -- these are **@Alex Duin (STAFF)** and **@Santiago** in Slack.
## We will take a zero tolerance approach to all racist, sexist and discriminatory behaviour.
We will not tolerate any kind of behaviour that creates a hostile and unpleasant environment for staff or volunteers. As a community, we aim to ensure that marginalised groups are supported and their voices raised.
## We’ll maintain each other’s right to privacy & protect sensitive information.
It’s important we remain GDPR compliant and not breach confidentiality. To do this, please do not share anyone’s contact information that you may come across in your work, and do not share outcomes or findings from our work without guidance from a staff member.
`
const thanksPara = `
Check out the video above introducing you to what’s in the Slack! You can also have a look at our handy map to give you an idea of what’s inside.
If you haven’t been in a Slack before, Slack will ask you to make an account in order to join ours. Once in, you’ll see #general where you can see announcements and information, and #welcome. Introduce yourself in #welcome so that the wonderful volunteers from the welcome team can help you with where next to go and what next to do.
So come on in and join in with what we’re up to!
`

type VolunteerFormSubmission = {
  agreeNda: boolean;
  agreeDpa: boolean;
  agreePrivacy: boolean;
  email: string;
  firstName?: string;
  lastName?: string;
}

type CodeOfConductSubmission = {
  agreeCoc: boolean;
}

const intitialVolunteerValues: VolunteerFormSubmission = {
  agreeNda: false,
  agreeDpa: false,
  agreePrivacy: false,
  email: '',
  firstName: '',
  lastName: '',
}

const initialCocValues: CodeOfConductSubmission = {
  agreeCoc: false
}

const volunteerFormSchema = Yup.object().shape({
  email: Yup.string().email("Please enter a valid email").required("Required"),
  agreeNda: Yup.boolean().required("Required").isTrue("Required"),
  agreeDpa: Yup.boolean().required("Required").isTrue("Required"),
  agreePrivacy: Yup.boolean()
})

const cocFormSchema = Yup.object().shape({
  agreeCoc: Yup.boolean().required("Please agree to our code of conduct").isTrue("Please agree to our code of conduct"),
})

const Volunteer: React.FC<{}> = (props) => {
  const [volunteerSubmitted, setVolunteerSubmitted] = useState(false)
  const [cocSubmitted, setCocSubmitted] = useState(false)
  const [firstName, setFirstName] = useState('')

  const greetingLine = firstName === '' ? 'Welcome!' : `Hey ${firstName}!`

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [volunteerSubmitted, cocSubmitted]);


  const handleVolunteerSubmit = async (values: VolunteerFormSubmission) =>  {

    if (values.firstName) {
      setFirstName(values.firstName)
    }
    
    return SubmitForm('/volunteer', values)
      .then(res => {
        console.log(res)
        if (res?.status === 200) {
          setVolunteerSubmitted(true)
        }
      })
  }

  const handleCocSubmit = async (values: CodeOfConductSubmission) => {
    if (values.agreeCoc) {
      setCocSubmitted(true)
    }
  }

  const communityHeader = (
    <Container className="py-5">
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          <img style={{ maxWidth: '100%' }} alt="logo" src={`/img/2021-05-28_Banner_Community.png`} />
        </Col>
      </Row>
    </Container>
  )

  const landingPage = (
    <>
      {communityHeader}
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <ReactMarkdown children={introPara} />
          </Col>
        </Row>
      </Container>
      <div className={'bg-neutral-200'}>
        <Container className="py-5" >
          <Row>
            <Col className='py-4' md={{ size: 6, offset: 3 }}>
              <h1>Sign up to Momentum Community</h1>
            </Col>
          </Row>
          <Row >
            <Col md={{ size: 6, offset: 3 }}>
              <Formik
                initialValues={intitialVolunteerValues}
                validationSchema={volunteerFormSchema}
                onSubmit={handleVolunteerSubmit}
              >
                {
                  ({ values, errors }) => (
                    <Form>
                      <Row>
                        <Col xs='6'>
                          <SingleLineField property="firstName" label="First name" errors={errors} />
                        </Col>
                        <Col xs='6'>
                          <SingleLineField property="lastName" label="Last name" errors={errors} />
                        </Col>
                      </Row>
                      <SingleLineField property="email" label="Enter your email" errors={errors} />
                      <CheckBoxField property='agreeNda' label='You agree to the terms of the [Non Disclosure Agreement](https://docs.google.com/document/d/1GJ_MXZxJKbf5--e_Lo9bxSLZh3eY5doeNE6_WNjguCQ/edit?usp=sharing)' errors={errors} />
                      <CheckBoxField property="agreeDpa" label="You agree to the terms of the [Data Processing Agreement](https://docs.google.com/document/d/14bbPc07lKpdb2zyM77HTz5dGo4_pSGe4HtIcWHc9uOw/edit?usp=sharing)" errors={errors} />
                      <CheckBoxField property="agreePrivacy" label="You agree to Momentum using the information you provide to keep you updated via email about Momentum’s campaigns and opportunities to get involved, both locally and on a national level, in line with their [privacy policy](https://peoplesmomentum.com/privacy-policy/)" errors={errors} />
                      <FormGroup>
                        <Button
                          color="primary"
                          outline
                          disabled={Object.keys(errors).length > 0}
                          type="submit"
                          id='submitForm'
                          block
                          className='bg-neutral-000'
                        >
                          Submit
                        </Button>
                        {
                          Object.keys(errors).length > 0 && (
                            <div className="red">Please make sure your entries are correct.</div>
                          )
                        }
                      </FormGroup>
                    </Form>
                  )
                }
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
      <Imprint />
    </>
  )

  const codeOfConductPage = (
    <>
      {communityHeader}
      <Container>
        <Row>
          <Col md={{size: 6, offset: 3}}>
            <ReactMarkdown children={codeOfConductPara}/>
            <Formik
              initialValues={initialCocValues}
              validationSchema={cocFormSchema}
              onSubmit={handleCocSubmit}
            >
              {
                ({ values, errors }) => (
                  <Form>
                    <CheckBoxField property="agreeCoc" label="You agree to the terms of the above code of conduct, as well as [the full code of conduct](https://docs.google.com/document/d/1fhHkMcY7RKsFictJW0QiI6MazHGGGqyJ6Np4vF8a6T4/edit)" errors={errors} />
                    <FormGroup>
                      <Button
                        color="primary"
                        outline
                        disabled={Object.keys(errors).length > 0}
                        type="submit"
                        id='submitForm'
                        block
                      >
                        Submit
                      </Button>
                      {
                        Object.keys(errors).length > 0 && (
                          <div className="red">Please make sure your entries are correct.</div>
                        )
                      }
                    </FormGroup>
                  </Form>
                )
              }
            </Formik>
          </Col>
        </Row>
      </Container>
      <Imprint />
    </>
  )

  const thanksPage = (
    <>
      <Container>
        {communityHeader}
        <Row>
          <Col md={{ size: 6, offset: 3 }}>
            <a className='btn btn-outline-primary btn-block' href='https://join.slack.com/t/momentumvolunteers/shared_invite/zt-dgtjtyrb-Yf0AVy7H9c5wRJsQNvTRaQ'>Join the Slack</a>
            <div className='mb-4'>
              <Iframe url='https://www.youtube-nocookie.com/embed/64RI6RV2Ze8' width="100%" height="400px" />
            </div>
            <h1>{greetingLine}</h1>
            <ReactMarkdown children={thanksPara} />
            <a className='btn btn-outline-primary btn-block' href='https://join.slack.com/t/momentumvolunteers/shared_invite/zt-dgtjtyrb-Yf0AVy7H9c5wRJsQNvTRaQ'>Join the Slack</a>
          </Col>
        </Row>
      </Container>
      <Imprint />
    </>
  )

  return (
    <>
      {
        !volunteerSubmitted ?
          landingPage
          :
          !cocSubmitted ?
            codeOfConductPage
            :
            thanksPage
      }
    </>
  )
}

export default Volunteer
