import React, { ReactNode } from 'react'
import { Field } from 'formik'
import { Button, FormGroup, Label } from 'reactstrap'
import TextareaAutosize from 'react-textarea-autosize'

import './FormGroup.scss'
import ReactMarkdown from 'react-markdown'

type FieldProps = {
  property: string
  label: string
  field_validator?: any
  errors?: any
  helptext?: ReactNode
  type?: string
  style?: any
  labelStyle?: any
  required?: boolean
  disabled?: boolean
}

type CheckBoxFieldProps = FieldProps & {
  value?: string
}

type TextAreaFieldProps = FieldProps & {
  word_limit?: number
  autoresize?: boolean
}

type SelectFieldProps = FieldProps & {
  options: string[]
}

type NoticeProps = {
  isWarning?: boolean,
  children?: React.ReactNode
}

export const SingleLineField: React.FC<FieldProps> = (props) => {
  const labelText = <ReactMarkdown children={props.label} components={{ p: 'span' }} />
  const errors = props.errors[props.property] && <span className="red">{props.errors[props.property]}</span>

  return (
    <FormGroup>
      <Label
        for={props.property}
        style={props.labelStyle}
      >
        {labelText}
        {props.required && <span className="red">{' '}*</span>}
        {errors}
      </Label>
      {props.helptext}
      <Field
        className="form-control"
        id={props.property}
        name={props.property}
        style={props.style}
        placeholder={props.label}
        validation={props.field_validator}
        type={props.type}
        disabled={props.disabled}
      />
    </FormGroup>
  )
}

export const TextAreaField: React.FC<TextAreaFieldProps> = (props) => {
  const wordLimitValidator = (value: string) => {
    if (props.word_limit) {
      const count = value.match(/\S+/g)?.length
      if (count && count > props.word_limit) {
        return `Please ensure your entry is less than ${props.word_limit} words. Currently it is ${count} words long.`
      }
    } else if (props.field_validator) {
      return props.field_validator(value)
    }
  }

  const style = props.style
  if (props.autoresize) {
    style.resize = 'none'
    style.overflowY = 'hidden'
  }

  return (
    <FormGroup>
      <Label for={props.property} style={props.labelStyle}>
        {props.label}
        {props.required && <span className="red">{' '}*</span>}
      </Label>
      {props.helptext}
      {props.word_limit && (
        <p style={{ fontSize: '0.8em' }}>
          Please ensure your answer is less than {props.word_limit} words long.
        </p>
      )}
      {props.errors[props.property] && <div className="error">{props.errors[props.property]}</div>}
      <Field
        className="form-control"
        as={props.autoresize ? TextareaAutosize : 'textarea'}
        id={props.property}
        name={props.property}
        style={style}
        spellCheck="false"
        validate={wordLimitValidator}
      />
    </FormGroup>
  )
}

export const CheckBoxField: React.FC<CheckBoxFieldProps> = (props) => {
  const label = <ReactMarkdown children={props.label} components={{ p: 'span' }} />
  const errors = props.errors[props.property] && (<span className="red" > {props.errors[props.property]}</span>) 
  
  return (
    <>
      {props.helptext}
      
      <div className={"form-check"}>
        <Field
          name={props.property}
          style={props.style}
          id={props.property}
          component="input"
          type="checkbox"
          value={props.value}
          validation={props.field_validator && props.field_validator}
        />
        <label
          htmlFor={props.property}
          className="form-check-label"
          style={props.labelStyle}
        >
          {label}
          {errors}
        </label>
      </div>
    </>
  )
}

export const RadioField: React.FC<SelectFieldProps> = (props) => {
  // Manually fix button height issues (seems like this app's CSS is unused...)
  const radioStyle = props.style || {}
  radioStyle.minHeight = 'unset'

  return (
    <FormGroup>
      <Label for={props.property} style={props.labelStyle}>
        {props.label}
        {props.required && <span className="red">{' '}*</span>}
      </Label>
      {props.helptext}
      {props.errors[props.property] && (<div className="error">{props.errors[props.property]}</div>)}
      <div style={{display: 'flex', width: '100%', gap: 30}}>
        {
          props.options.map(option => (
            <label style={{display: 'flex', alignItems: 'center', gap: '.3rem', margin: 0}}>
              {option}
              <Field
                name={props.property}
                style={radioStyle}
                type="radio"
                value={option}
              />
            </label>
          ))
        }
      </div>
    </FormGroup>
  )
}

export const OptionField: React.FC<SelectFieldProps> = (props) => {
  return (
    <FormGroup>
      <Label for={props.property} style={props.labelStyle}>
        {props.label}
        {props.required && <span className="red">{' '}*</span>}
      </Label>
      {props.helptext}
      {props.errors[props.property] && (<div className="error">{props.errors[props.property]}</div>)}
      <Field
        name={props.property}
        style={props.style}
        as="select"
        children={props.options.map((optiontext: string) => <option key={optiontext}>{optiontext}</option>)}
      />
    </FormGroup>
  )
}

export const Notice = ({isWarning = false, children}: NoticeProps) => (
  <FormGroup
    className={isWarning ? "red" : undefined}
    style={{ textAlign: 'center' }}
  >
    { isWarning ? (<strong>{children}</strong>) : children }
  </FormGroup>
)

export const SubmitButton = ({disabled, children}) => (
  <FormGroup>
    <Button
      id="submitButton"
      className="btn btn-outline-primary btn-block"
      type="submit"
      disabled={disabled}
    >
      { children }
    </Button>
  </FormGroup>
)

export async function SubmitForm<T>(endpoint: string, values: T) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values)
  }

  try {
    const response = await fetch(endpoint, options)
    if (!response.ok) {
      console.log(JSON.stringify(response));
      return response
    }
    return response
  } catch (e) {
    console.error(e)
  }
}
