// Common lobby page components
// Maybe I'll move these in with all the other components at some point...
import React, { useEffect, useRef } from 'react'
import { useFormikContext } from 'formik'

import { RedirectOnSubmit } from '../../../components/Common/CommonComponents'
import { SubmissionStatus } from '../../../components/Common/CommonUtils'
import { Notice, TextAreaField, SubmitButton } from '../../../components/FormGroup/FormGroup'
import { maybeEvaluate } from './LobbyUtils'

// Shows a warning or a prompt to edit the email content
const LobbyNotice = ({ submissionStatus, errors }) => {
  if (submissionStatus === SubmissionStatus.Failure) {
    return <Notice isWarning>⚠️ There was an error sending your email. Please try again later. ⚠️</Notice>
  } else if (Object.keys(errors).length > 0) {
    return <Notice isWarning>⚠️ Please make sure your email address is correct. ⚠️</Notice>
  } else {
    return <Notice>📣 For maximum impact, edit the email text above, then click send! 📣</Notice>
  }
}

// Manually sets email prose after page load so that the textarea's autoresize kicks in
// TODO: This should probably be built into TextAreaField somehow
// (or wrapped up alongside it as part of some kind of eg. "LobbyEmailField" component)
const ProseLoader = ({ body }) => {
  const ctx: any = useFormikContext()
  const { setFieldValue } = ctx

  // NOTE: In theory, neither value should change, but if we don't include them in the dependency array
  // the linter will cry about it,
  useEffect(() => {
    setFieldValue('body', body)
  }, [body, setFieldValue])

  return <></>
}

// Looks up the user's contituency based on the contents of the postcode field,
// then uses that to inject the relevant MP data into the form
const ConstituencyFinder = ({ mps, body, newBoundaries = false }) => {
  const ctx: any = useFormikContext()
  const {
    values: {postcode, constituency},
    setFieldValue,
  } = ctx

  useEffect(() => {
    const enc = encodeURIComponent
    let isCurrent = true
    if (postcode) {
      fetch(`https://api.postcodes.io/postcodes/${enc(postcode)}`).then(async response => {
        console.log(response)
        if (!response.ok) return
        const json = await response.json()
        if (isCurrent) {
          const constituency =
            newBoundaries ? json.result.parliamentary_constituency_2024 : json.result.parliamentary_constituency
          setFieldValue('postcode', json.result.postcode)
          setFieldValue('constituency', constituency)
          const row = mps.filter(row => row.Constituency === constituency)[0]
          setFieldValue('MP', row)
          setFieldValue('body', maybeEvaluate(body, row))
        }
      })
    }
    return () => { isCurrent = false }
  }, [postcode, setFieldValue, mps, body, newBoundaries])

  if (constituency) {
    return <p>Constituency: {constituency}</p>
  } else {
    return <></>
  }
}

// Scrolls the page to itself when the constituency value is filled out
const FormAnchor = () => {
  const ctx: any = useFormikContext()
  const { values: { constituency } } = ctx

  // Scroll down to this element once the constituency is set
  const formAnchorRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (formAnchorRef.current !== null) {
      formAnchorRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [constituency])

  return constituency ? (<div ref={formAnchorRef} />) : (<></>)
}

const buttonText = {
  [SubmissionStatus.Unsubmitted]: 'Send',
  [SubmissionStatus.Submitting]: 'Sending...',
  [SubmissionStatus.Success]: 'Sent!',
  [SubmissionStatus.Failure]: 'Send'
}

const LobbySubmitButton = ({submissionStatus, errors}) => (
  <SubmitButton disabled={[SubmissionStatus.Submitting, SubmissionStatus.Success].includes(submissionStatus)}>
    { buttonText[submissionStatus] }
  </SubmitButton>
)

// Email body textarea
const EmailBodyField = ({ property, errors }) => (
  <TextAreaField
    style={{
      marginTop: '-.5rem',
      marginLeft: '-13px',
      marginRight: '-13px',
      marginBottom: '-.5rem',
      width: 'calc(100% + 26px)',
    }}
    label="Edit your email"
    labelStyle={{ display: 'none' }}
    property={property}
    autoresize
    errors={errors}
  />
)

// Redirects to thanks page on successful submission
const ThanksPageRedirect = ({ submissionStatus, senderName, recipientName, copy }) => (
  <RedirectOnSubmit
    submissionStatus={submissionStatus}
    path='/thank-you'
    state={{givenName: senderName, mpName: recipientName, shareCopy: copy}}
  />
)

export {
  LobbyNotice,
  ProseLoader,
  ConstituencyFinder,
  FormAnchor,
  LobbySubmitButton,
  EmailBodyField,
  ThanksPageRedirect
}
