import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { Container, Row, Col, FormGroup } from 'reactstrap'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import Axios from 'axios'

import { Imprint } from '../../components/Common/CommonComponents'

const AGM: React.FC = () => {
  type RawSlate = {
    clp: string,
    blp: string,
    candidates?: {
      blp_positions: Array<Candidate>,
      clp_positions: Array<Candidate>,
    },
    clpMeeting?: string,
    blpMeeting?: string,
    district: string,
  }

  type Meeting = {
    Date: string,
    Time: string,
  }

  type Slate = {
    blp?: string,
    clp?: string,
    blp_candidates?: Array<Candidate>,
    blp_delegates?: Array<Candidate>,
    clp_candidates?: Array<Candidate>,
    blp_meeting?: Meeting,
    clp_meeting?: Meeting
  }

  type Candidate = {
    [key: string]: string | boolean | Array<any> | undefined,
    Name: string,
    BLP: string,
    CLP: string,
    Branch_Position?: string,
    Branch_Delegate?: boolean,
    CLP_Position?: string,
    Photo?: Array<any>
  }

  const [slate, setSlate] = useState<Slate>({})
  const [error, setError] = useState<String>("")
  const [postcode, setPostcode] = useState("")
  const [district, setDistrict] = useState<String>("The Labour Party")

  const slatemaker = (data: RawSlate) => {
    if (!data.candidates) {
      const emptySlate: Slate = {
        clp: data.clp,
        blp: data.blp
      }
      return emptySlate
    }

    const rankPositions = (positions: Candidate[], key: string) => {

      const positionOrder = ["Chair", "Vice Chair", "Secretary", "Policy Officer", "Women's Officer", "BAME Officer", "Disabilities Officer", "Youth Officer", "Trade Union Liaison Officer (TULO)", "Political Education Officer", "Fundraising Officer", "Local Government Officer"]

      const importantCandidatesFirst = (arr: any, key: any) => {

        return arr.sort((candidate_a: any, candidate_b: any) => {

          const getPositionIndex = (candidate: any) => {
            return positionOrder.indexOf(candidate[key])
          }

          return (getPositionIndex(candidate_a) - getPositionIndex(candidate_b))
        })
      }

      if (positions) {

        const positionIsIncluded = (positions: Candidate[], bool: boolean = true) => {
          return positions.filter(candidate => bool === positionOrder.includes(String(candidate[key])))
        }

        const sortCandidatesAlphabetically = (arr: Candidate[]) => {
          return arr.sort(( cand1, cand2) => {
            const cand1Name = cand1.Name.toUpperCase()
            const cand2Name = cand2.Name.toUpperCase()
            return (cand1Name < cand2Name) ? -1 : (cand1Name > cand2Name) ? 1 : 0
          })
        }

        const important_positions: Candidate[] = importantCandidatesFirst(positionIsIncluded(positions), key)
        const other_positions: Candidate[] = importantCandidatesFirst(positionIsIncluded(positions, false), key)
        const other_positions_sorted = sortCandidatesAlphabetically(other_positions)

        return [...important_positions, ...other_positions_sorted]
      }
    }


    const slate: Slate = {
      "blp": data.blp,
      "clp": data.clp,
      "blp_candidates": rankPositions(data.candidates.blp_positions.filter((candidate: Candidate) => candidate.Branch_Position), "Branch_Position"),
      "blp_delegates": rankPositions(data.candidates.blp_positions.filter((candidate: Candidate) => candidate.Branch_Delegate), ""),
      "clp_candidates": rankPositions(data.candidates.clp_positions, "CLP_Position")
    }

    const makeMeeting = (meetingString: string) => {
      const meetingDate = parseISO(meetingString)
      const time = format(meetingDate, "h") + ":" + format(meetingDate, "mmaa").toLowerCase()
      const date = format(meetingDate, "eeee do MMMM")
      const meetingForSlate: Meeting = {
        Date: date,
        Time: time
      }
      return meetingForSlate
    }

    if (data.blpMeeting) {
      slate.blp_meeting = makeMeeting(data.blpMeeting)
    }

    if (data.clpMeeting) {
      slate.clp_meeting = makeMeeting(data.clpMeeting)
    }

    return slate
  }

  const getContext = async () => {
    try {
      const context = await Axios.request({
        method: "GET",
        url: `${process.env.PUBLIC_URL}/slate/${postcode}`
      })
      setSlate(slatemaker(context.data))
      setDistrict(context.data.district)
      setError("")
    } catch (error) {
      if (error.message === "Request failed with status code 500") {
        setError("Please enter a postcode")
      }
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostcode(e.target.value)
  }

  const handleSubmit = (e: React.MouseEvent) => {
    e.preventDefault()
    getContext()
  }

  const clearData = (e: React.MouseEvent) => {
    e.preventDefault()
    setSlate({})
    setDistrict("The Labour Party")
    setPostcode("")
  }

  const PUBLIC_URL = process.env.PUBLIC_URL || "https://forms.peoplesmomentum.com"
  const metaTitle = "Transform your CLP"
  const metaUrl = `${PUBLIC_URL}/meeting`
  const metaDescription = "Find the socialist slate to vote for in your local Labour party branch and CLP "
  const metaImg = `https://res.cloudinary.com/mtmhost/image/upload/v1608132526/2020-12-16_Meta_AgmForms_gbymb6.png`

  const CandidateDisplay: React.FC<{ candidates: Array<Candidate>, useKey: string, showPosition?: boolean }> = (props) => {
    return (
      <>
        {props.candidates.map((candidate: Candidate) => {
          return (
            <div>
              <h3>
                {props.showPosition && <span className="pink">{candidate[props.useKey]} – </span>}
                <span>{candidate.Name}</span>
              </h3>
            </div>
          )
        })}
      </>
    )
  }

  const noPositions =
    (!slate.blp_candidates || slate.blp_candidates.length === 0) &&
    (!slate.clp_candidates || slate.clp_candidates.length === 0) &&
    (!slate.blp_delegates || slate.blp_delegates.length === 0) &&
    !slate.blp_meeting &&
    !slate.clp_meeting

  const lg_layout = { size: 6, offset: 3 }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="title" content={metaTitle} />
        <meta name="description" content={metaDescription} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={metaUrl} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={metaImg} />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={metaUrl} />
        <meta property="twitter:title" content={metaTitle} />
        <meta property="twitter:description" content={metaDescription} />
        <meta property="twitter:image" content={metaImg} />
      </Helmet>

      <Container>
        <Row>
          <Col lg={lg_layout}>
            {
              district === "The Labour Party" ? (
                <h1 className="pt-5">Transforming <br /><span className="pink">The Labour Party</span> 🌹</h1>
              ) : (
                <h1 className="pt-5">Transform <br /><span className="pink">{district}</span> 🌹</h1>
              )
            }
            <p className="pb-5 m-0">
              <strong>From</strong>{' '}
              <img style={{ maxHeight: "1em" }} alt="logo" src={`/img/2020-11-26_Logo_Full-Red-Small.png`} />
            </p>
          </Col>
        </Row>
      </Container>

      <Container fluid className="bg-mtm-neutral-200">
        <Container>
          <Row>
            <Col className="pb-4" lg={lg_layout}>
              <h1 className="pt-5 pb-4">We're building a socialist Labour Party</h1>
              <p>It has been a hard year, from the electoral defeat of 2019, through the continual threat and grinding betrayal of the Tories mishandling of the Coronavirus crisis. But one thing remains apparent: the need for a socialist alternative has never been greater. </p>
              <p>Labour must win an election in 2024. To do that, it must implement the transformative socialist platform Keir Starmer was elected to uphold. Our communities deserve that radical agenda; we cannot merely paper over the deep rifts left by years-long Tory austerity and a hard right-wing government which serves only a privileged few.</p>
              <p>In these upcoming elections, Labour members can elect active, fighting, socialist committees in their branches and CLPs, to hold the party to account and stand up for socialist values in our community and across the country. Enter your postcode below to find out which candidates are endorsed by your local Momentum group.</p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        {
          noPositions && !slate.clp && (
            <form>
              <Row>
                <Col lg={lg_layout}>
                  <FormGroup>
                    <p>Enter your postcode here to find the socialist slate for your area:</p>
                    <input className="form-control" onChange={handleInputChange} type="text"></input>
                    <button className="btn btn-outline-primary btn-block mt-3" onClick={handleSubmit}>Submit</button>
                    {error.length > 0 && <label htmlFor="text">{error}</label>}
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-4 mt-3">
                <Col>
                </Col>
              </Row>
            </form>
          )
        }

        <Row>
          <Col lg={lg_layout}>
            {
              postcode && slate.clp && (
                <p>
                  Postcode not {postcode.toLocaleUpperCase()}?{' '}
                  <button onClick={clearData}>Enter another postcode</button>.
                </p>
              )
            }

            {
              slate.blp && !noPositions && (
                <>
                  <h1 className="mt-5">Your branch is <span className="pink">{slate.blp}</span></h1>
                  <hr className="mt-0" style={{ border: "2px solid black" }} />
                </>
              )
            }

            {
              slate.blp_meeting ? (
                <div className="bg-mtm-neutral-200 p-5 my-5">
                  <h2 className="p-0 m-0">{slate.blp} AGM will be held at <span className="pink">{slate.blp_meeting.Time}</span> on <span className="pink">{slate.blp_meeting.Date}</span></h2>
                  <br />
                  <p className="p-0 m-0">The link should have been emailed to you from your branch secretary</p>
                </div>
              ) : slate.blp && !noPositions && (
                <div className="bg-mtm-neutral-200 p-5 my-5">
                  <h2 className="p-0 m-0">We don't have a time yet for {slate.blp} AGM</h2>
                  <p className="p-0 m-0">Do you know when the {slate.blp} AGM is? If so, please email <a href={`mailto:${process.env.REACT_APP_ORGANISER_EMAIL || "info@peoplesmomentum.com"}`}>{`${process.env.REACT_APP_ORGANISER_EMAIL || "info@peoplesmomentum.com"}`}</a>.</p>
                </div>
              )
            }

            {
              slate.blp_candidates && slate.blp_candidates.length > 0 && (
                <>
                  <h2 className="mt-5">Branch officers in <span className="pink">{slate.blp}</span></h2>
                  <p>Please vote for the following as branch officers in {slate.blp} branch:</p>
                  <CandidateDisplay
                    candidates={slate.blp_candidates}
                    useKey="Branch_Position"
                    showPosition
                  />
                </>
              )
            }

            {
              slate.blp_delegates && slate.blp_delegates.length > 0 && (
                <>
                  <h2 className="mt-5">Branch delegates in <span className="pink">{slate.blp}</span></h2>
                  <p>Please vote for the following as branch delegates in {slate.blp} branch:</p>
                  <CandidateDisplay
                    candidates={slate.blp_delegates}
                    useKey="Branch_Delegate"
                  />
                </>
              )
            }

            {
              slate.clp && (
                <>
                  <h1 className="mt-5">
                    Your CLP is <span className="pink">{slate.clp}</span>
                    {noPositions && slate.blp &&
                      <> & your branch is <span className="pink">{slate.blp}</span></>
                    }
                  </h1>
                  {!noPositions && <hr className="mt-0" style={{ border: "2px solid black" }} />}
                </>
              )
            }

            {
              slate.clp_meeting ? (
                <div className="bg-mtm-neutral-200 p-5 my-5">
                  <h2 className="p-0 m-0">{slate.clp} AGM will be held at <span className="pink">{slate.clp_meeting.Time}</span> on <span className="pink">{slate.clp_meeting.Date}</span></h2>
                  <br />
                  <p className="p-0 m-0">The link should have been emailed to you from your CLP secretary</p>
                </div>
              ) : slate.clp && !noPositions && (
                <div className="bg-mtm-neutral-200 p-5 my-5">
                  <h2 className="p-0 m-0">We don't have a time yet for {slate.clp} AGM</h2>
                  <p className="p-0 m-0">Do you know when the {slate.clp} AGM is? If so, please email <a href={`mailto:${process.env.REACT_APP_ORGANISER_EMAIL || "info@peoplesmomentum.com"}`}>{`${process.env.REACT_APP_ORGANISER_EMAIL || "info@peoplesmomentum.com"}`}</a>.</p>
                </div>
              )
            }

            {
              slate.clp_candidates && slate.clp_candidates.length > 0 && (
                <>
                  <h2 className="mt-5">CLP Officers in <span className="pink">{slate.clp}</span></h2>
                  <p>Please vote for the following as CLP officers in {slate.clp}:</p>
                  <CandidateDisplay
                    candidates={slate.clp_candidates}
                    useKey="CLP_Position"
                    showPosition
                  />
                </>
              )
            }

            {
              noPositions && slate.clp && (
                <>
                  <hr className="my-5" style={{ border: "2px solid black" }} />
                  <h1>Uh oh!</h1>
                  <h2>It looks like we don't have your AGM.</h2>
                  <p>Looking to organise in your area? Join the fight for a fairer society and socialism in our lifetime. <a target="_blank" rel="noopener noreferrer" href="https://join.peoplesmomentum.com/">Join Momentum today</a>, and also <a target="_blank" rel="noopener noreferrer" href={`https://www.mycampaignmap.com/recommendations/${postcode}`}>find events in your area to get involved with</a> on My Campaign Map.</p>
                </>
              )
            }
          </Col>
        </Row>
      </Container>
      <Imprint />
    </>
  )
}

export default AGM
