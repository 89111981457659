import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ConstituencyFinder, FormAnchor, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

import MPs from '../MPs.json'

const PUBLIC_URL = process.env.PUBLIC_URL || "https://community.peoplesmomentum.com"

// shouldEmail returns false for non-Labour MPs so there's no point adding Corbyn in this array
// I've hardcoded him to show up as supporting anyway
const strikeSupporters = [
  "Ms Diane Abbott",
  "Tahir Ali",
  "Mike Amesbury",
  "Paula Barker",
  "Apsana Begum",
  "Olivia Blake",
  "Ms Karen Buck",
  "Richard Burgon",
  "Dawn Butler",
  "Ian Byrne",
  "Dan Carden",
  "Marsha De Cordova",
  "Peter Dowd",
  "Mary Kelly Foy",
  "Barry Gardiner",
  "Margaret Greenwood",
  "Kate Hollern",
  "Rachel Hopkins",
  "Imran Hussain",
  "Kim Johnson",
  "Afzal Khan",
  "Stephen Kinnock",
  "Ian Lavery",
  "Clive Lewis",
  "Rebecca Long Bailey",
  "Justin Madders",
  "Andy McDonald",
  "John McDonnell",
  "Ian Mearns",
  "Navendu Mishra",
  "Grahame Morris",
  "Charlotte Nichols",
  "Kate Osborne",
  "Angela Rayner",
  "Bell Ribeiro-Addy",
  "Cat Smith",
  "Wes Streeting",
  "Zarah Sultana",
  "Sam Tarry",
  "Jon Trickett",
  "Karl Turner",
  "Mick Whitley",
  "Nadia Whittome",
  "Beth Winter",
  "Florence Eshalomi",
  "Rachael Maskell",
  "Kate Osamor",
  "Luke Pollard",
  "Lloyd Russell-Moyle",
  "Alex Sobel",
  "Mr Tanmanjeet Singh Dhesi",
]

// If the MP is in the Labour Party and not in the list of supporters, we should email them
// Otherwise email Labour directly
const shouldEmail = (MP: any) => (MP && MP["Party"] === "Labour") && !strikeSupporters.includes((MP["Name (Display as)"] as string))

// Page content (this could probably be pushed out into another file)

const subjectsForMP = [
  `Are you supporting striking rail workers?`,
  `Support the rail workers!`,
  `Stand with the RMT!`,
]

const subjectsForKeith = [
  `Support the rail workers!`,
  `Stand with the RMT!`,
  `Support the Workers, Drop the Ban!`,
]

const content = {
  // Page
  title: `Labour MPs: Stand with Striking Rail Workers`,
  intro: `Right now, tens of thousands of rail workers are on strike to defend their pay, conditions and livelihoods. These workers kept the country moving throughout the pandemic - but now they’ve been targeted by this rotten Tory Government and abandoned by Network Rail.

The Labour Party was founded by trade unionists - including from the RMT - to champion the interests of workers in Parliament. But despite spiralling inflation and a cost-of-living crisis, Keir Starmer is failing to stand with or up for the RMT and trade union movement. 

Polling shows the majority of the public support these strikes - people want a Labour Party that stands up for workers, not one that sits on the fence.

Labour MPs must do their job and support the rail strike, on the picket lines and in Parliament.`,

  // Email
  recipients: (vs: Values) => shouldEmail(vs.MP) ? vs.MP.Email : 'keir.starmer.mp@parliament.uk',
  subject: (vs: Values) => shouldEmail(vs.MP) ? subjectsForMP : subjectsForKeith,
  header: (vs: Values) => `Dear ${vs.MP && shouldEmail(vs.MP) ? vs.MP['Name (Display as)'] : 'Keir Starmer'},`,
  body: (MP: any) => shouldEmail(MP)
    ? `Millions of us are struggling in a cost-of-living crisis - but we’re told that we can’t demand the pay rises we need. 

So over the past couple of days, I’ve been inspired by the thousands of rail workers standing up for their livelihoods, and by the RMT’s leadership standing up for ordinary people. As polls show, this support for the workers is shared by a majority of the British public.

Rail workers were rightly celebrated during the pandemic for keeping the country moving. It isn’t right that they now face real-terms cuts to their pay, terms and conditions. But to beat the rotten Tory government responsible, we need a united labour movement standing together.

As one of your constituents, I am asking you to support the striking rail workers, and attend a picket line in solidarity.

So can you confirm to me, yes or no, whether you support these strikes and will be attending a picket line this week? 

I look forward to receiving your response.`
  : `Millions of us are struggling in a cost-of-living crisis - but we’re told that we can’t demand the pay rises we need. 

So over the past couple of days, I’ve been inspired by the thousands of rail workers standing up for their livelihoods, and by the RMT’s leadership standing up for ordinary people. As polls show, this support for the workers is shared by a majority of the British public.

Rail workers were rightly celebrated during the pandemic for keeping the country moving. It isn’t right that they now face real-terms cuts to their pay, terms and conditions. But to beat the rotten Tory government responsible, we need a united labour movement standing together.

I am asking you to keep to your pledges and to stand with our trade unions in their hour of need, by publicly supporting the strike and dropping the ban on shadow ministers attending picket lines.`,
  footer: (vs: Values) => [
    shouldEmail(vs.MP) ? 'Yours in solidarity,' : 'Best wishes,',
    `${vs.givenName} ${vs.familyName}`.length > 1
      ? `${vs.givenName} ${vs.familyName}`
      : 'A concerned constituent',
  ].join('\n\n'),
  share: (vs: Values) => `I just told ${shouldEmail(vs.MP) ? 'my MP' : 'Keir Starmer'} to support the rail workers! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.YouTube,
  urlOrSlug: 'cbLFHt0esvY',

  // NB
  eventCode: '2022-06 Lobby RMT',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/rmt?lobby`,
  description: `Use this to tell your MP to support the rail workers!`,
  img: `${PUBLIC_URL}/img/labour_mps_should_support_rail_strikers.jpg`,
  imgAlt: `Labour MPs should support rail strikers`,
}

const messageText = (MP: any) => {
  const mpName = MP?.["Name (Display as)"]
  if (mpName === "Apsana Begum") {
    return (
      <>
        Apsana Begum is currently on sick leave following a concerted campaign of misogynistic abuse. She has previously supported RMT workers on strike.* Solidarity.
        <br />
        <br />
        Use our lobbying tool below to demand Keir Starmer publicly supports the strike, and drops his outrageous ban on shadow ministers attending picket lines.
      </>
    )
  } else if (mpName === "Jeremy Corbyn") {
    return "Jeremy Corbyn has already voiced support for the rail workers.* Use our lobbying tool below to demand Keir Starmer publicly supports the strike, and drops his outrageous ban on shadow ministers attending picket lines."
  } else if (shouldEmail(MP)) {
    return `${MP?.["Name (Display as)"]} has not yet publicly supported the strike.* Use our lobbying tool below to demand your Labour MP commits to supporting the rail workers' strike, and attending a picket.`
  } else {
    return `${MP?.["Party"] === "Labour" ? `${MP?.["Name (Display as)"]} has already voiced support for the rail workers.* ` : ''}Use our lobbying tool below to demand Keir Starmer publicly supports the strike, and drops his outrageous ban on shadow ministers attending picket lines.`
  }
}

const RMT = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          bame: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <h1>{content.title}</h1>

                  <ReactMarkdown>{content.intro}</ReactMarkdown>
 
                  <SingleLineField
                    property="postcode"
                    label="First, enter your UK postcode"
                    errors={errors}
                  />
                  <ConstituencyFinder mps={MPs} body={content.body} />
 
                  { values.constituency && (
                    <>
                      {
                        messageText(values?.MP)
                      }
                      <SingleLineField
                        property="givenName"
                        label="First name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="familyName"
                        label="Last name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="email"
                        label="Email address"
                        errors={errors}
                        type="email"
                        required
                      />
                      <SingleLineField
                        property="mobile"
                        label="Mobile phone"
                        errors={errors}
                      />
                      <OptionField
                        property="labour"
                        label="Are you a Labour Party member?"
                        options={["--", "Yes", "No"]}
                        errors={errors}
                      />
                      <OptionField
                        property="bame"
                        label="Do you consider yourself to be an ethnic minority?"
                        options={["--", "Yes", "No"]}
                        errors={errors}
                      />
                      {
                        // <OptionField property="union" label="Which union are you in?" options={["--", ...unions]} errors={errors} />
                      }
                    </>
                  )}
                  <FormAnchor />
                </Col>
              </Row>
            </Container>
 
            { values.constituency && (
              <>
                <div className="bg-neutral-200">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="12" md="6">
                        <h2>Edit your email</h2>

                        <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                        <EmailBodyField property="body" errors={errors} />

                        <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                      </Col>
                    </Row>
                  </Container>
                </div>
 
                <Container>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      <LobbyNotice
                        errors={errors}
                        submissionStatus={submissionStatus}
                      />

                      <OptInField property="optIn" errors={errors} />

                      <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                    </Col>
                  </Row>
                </Container>

                <Container style={{marginTop: 0, marginBottom: 15}}>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      * Has your MP replied to you declaring their support for the strike? Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                    </Col>
                  </Row>
                </Container>
              </>
            ) }

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName={
                values.MP && shouldEmail(values.MP) ? (values.MP as any)['Name (Display as)'] : 'Keir Starmer'
              }
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default RMT
