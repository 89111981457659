import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ProseLoader, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Yours in solidarity,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

const emailSubjects = [
  'Stand Up for Real Labour Values',
]

const content = {
  // Page
  title: `NPF: Stand Up for Real Labour Values`,
  intro: `Labour's National Policy Forum process concludes this weekend in Nottingham, and representatives will vote to decide the party programme, ahead of Labour's next manifesto.

Under the Tories, **Britain is broken**. Our NHS is on its knees, privatised public services like water and energy are failing and expensive, millions of children are living in poverty and people don't have enough to make ends meet.

It's time for change. **We need a Labour Party which is true to its values**, which scraps cruel Tory policies like the two-child benefit cap and adopts ambitious policies on a par with the post-war Labour Government, not more of the same.

So we're asking NPF representatives to back a series of key amendments, to be funded by new taxes on wealth. Amendments include: **abolishing the two-child benefits cap, democratic public ownership, free school meals, repealing repressive Tory laws, housing for the many, saving the NHS, and a new deal for working people**.

Agree that these should be given a vote and form part of Labour's manifesto? **Use our lobbying tool to write to the National Policy Forum** asking representatives to vote for these amendments this weekend.`,

  // Email
  recipients: ['policyforum@labour.org.uk'],
  subject: emailSubjects,
  header: (vs: Values) => `FAO NPF representatives,`,
  body: `With a General Election looming, Labour is in prime position to beat the Tories. But we can't fix a broken Britain on the cheap. Instead, we need to follow in the footsteps of the post-war Labour Government by investing in Britain and taking public services back into public hands. Labour can't sort the Tories' mess by emulating their economic plans, or keeping cruel policies like the two-child benefit cap.

As National Policy Forum representatives, who will be voting on the amendments brought to Nottingham this weekend, I am asking you to back transformative policies to form the party's programme, including:

- Abolishing the two-child benefits cap - and scrapping punitive sanctions, while raising sick pay and universal credit.
- Democratic public ownership - of rail, mail, energy and water.
- Free school meals - for all primary school children.
- Repeal repressive Tory laws - restoring democratic freedoms by repealing the Illegal Migration Bill, Public Order Act, Voter ID and PCS Bill.
- Housing for the many - the mass building of council homes and the introduction of rent controls.
- Save the NHS - end all means of privatisation through the renationalisation of a publicly-funded NHS and a new National Care Service.
- New deal for working people - £15-an-hour minimum wage, public sector pay increase in line with inflation, and the repeal of anti-trade union laws.

It's up to you to stand up for real Labour values.`,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just demanded that NPF reps stand up for real Labour values! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/npf_banner.png',

  // NB
  eventCode: '2023-07 Lobby NPF',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/npf?lobby`,
  description: `Use this to demand that NPF reps stand up for real Labour values!`,
  img: `${PUBLIC_URL}/img/npf_preview.png`,
  imgAlt: `NPF: Stand Up for Real Labour Values`,
}

// Components

const NPF = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  // TODO: Review this postcode regex - we've gotten reports of it not allowing some valid postcodes
  // (although I've not been given any examples of these failing postcodes yet,)
  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <ProseLoader body={content.body} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>

            <div className="bg-neutral-200">
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" md="6">
                    <h2>Edit your email</h2>

                    <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                    <EmailBodyField property="body" errors={errors} />

                    <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <LobbyNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName="the NPF representatives"
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default NPF
