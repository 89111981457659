import React, { useEffect, useState } from 'react'
import { Notice, SubmitButton } from '../../../components/FormGroup/FormGroup'
import { RedirectOnSubmit } from '../../../components/Common/CommonComponents'
import { SubmissionStatus } from '../../../components/Common/CommonUtils'

// Shows a warning or a prompt to edit the email content
const PetitionNotice = ({ submissionStatus, errors }) => {
  if (submissionStatus === SubmissionStatus.Failure) {
    return <Notice isWarning>⚠️ There was an error signing the petition. Please try again later.⚠️</Notice>
  } else if (Object.keys(errors).length > 0) {
    return <Notice isWarning>⚠️ Please make sure your email address is correct. ⚠️</Notice>
  } else {
    return null
  }
}

const buttonText = {
  [SubmissionStatus.Unsubmitted]: 'Sign',
  [SubmissionStatus.Submitting]: 'Signing...',
  [SubmissionStatus.Success]: 'Signed!',
  [SubmissionStatus.Failure]: 'Sign'
}

const PetitionSubmitButton = ({submissionStatus}) => (
  <SubmitButton disabled={[SubmissionStatus.Submitting, SubmissionStatus.Success].includes(submissionStatus)}>
    { buttonText[submissionStatus] }
  </SubmitButton>
)

// Redirects to thanks page on successful submission
const ThanksPageRedirect = ({ submissionStatus, senderName, copy }) => (
  <RedirectOnSubmit
    submissionStatus={submissionStatus}
    path='/petition/thank-you'
    state={{givenName: senderName, shareCopy: copy}}
  />
)

// Returns a string indicating the number of signatures
const formatSignatureCount = signatureCount => {
  if (isNaN(signatureCount)) return 'Loading signatures...'
  if (signatureCount === 0) return 'No signatures yet. Be the first!'
  return `Signed by ${signatureCount} ${signatureCount <= 1 ? 'person' : 'people'}!`
}

// Fetches and displays the signature count for the given petition
const SignatureCount = ({petitionId}) => {
  const [signatureCount, setSignatureCount] = useState('...')

  useEffect(() => {
    const getSignatureCount = async () => {
      try {
        const response = await fetch(`/getPetition/${petitionId}`)
        const json = await response.json()
        setSignatureCount(json.data.number_of_signatures)
      } catch (e) {
        setSignatureCount('?')
      }
    }
    getSignatureCount()
  }, [petitionId])

  return (
    <div style={{ textAlign: 'center' }}>
      <p>
        <strong>{formatSignatureCount(parseInt(signatureCount))}</strong>
      </p>
    </div>
  )
}

export {
  ThanksPageRedirect,
  PetitionSubmitButton,
  PetitionNotice,
  SignatureCount,
}
