import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  ThanksPageRedirect, PetitionSubmitButton, PetitionNotice, SignatureCount
} from '../Common/PetitionComponents'
import { handleSubmit } from '../Common/PetitionUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

const content = {
  // Page
  title: `Labour: Repeal Tory Laws`,
  intro: `*Suella Braverman's time as Home Secretary is over, but her authoritarian legacy lives on, as Britain is still subject to a huge array of repressive Tory laws. Add your name to this statement and let's build pressure for the next Labour Government to commit to repeal these anti-democratic laws.*

The Tories have implemented draconian measures to stifle dissent. Both the Public Order Bill and the Police, Crime, Sentencing & Courts Act **criminalise protests by expanding police powers** to crackdown on basic forms of resistance.

Furthermore, **voter ID laws were introduced this year** in a blatant act of voter suppression, despite zero evidence of voter fraud. **Thousands of voters were turned away** at the ballot box, and an all-party [report](https://www.theguardian.com/politics/2023/sep/11/voter-id-in-england-led-to-racial-and-disability-discrimination-report-finds) found the laws to be discriminatory towards minority voters. But this isn't the only attack on civil liberties.

The Illegal Migration Bill curtails the right to seek asylum for vulnerable migrants. Meanwhile, the anti-strike bill was passed to clamp down on industrial action, although public support for recent strikes has been widespread.

**The freedom to protest, vote, strike and seek asylum are all under threat.** Labour has pledged to repeal the anti-strike law if in government - **but it must do more!** We urge Labour to reverse Britain's lurch towards authoritarianism by **repealing all** repressive Tory laws under a future Labour government, and stand up for our democratic rights!`,

  // Share
  share: (vs: Values) => `I just demanded that Labour commit to repealing draconian Tory laws! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/tory_laws_banner.png',

  // NB
  eventCode: '2023-11 Petition Tory Laws',

  // Petition ID
  petitionId: 3,
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/tory-laws?lobby`,
  description: `Sign our petition to demand Labour commits to repealing draconian Tory laws!`,
  img: `${PUBLIC_URL}/img/tory_laws_banner.png`,
  imgAlt: `Labour: Repeal Tory Laws`,
}

const ToryLaws = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SignatureCount petitionId={content.petitionId} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <PetitionNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <PetitionSubmitButton submissionStatus={submissionStatus} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default ToryLaws
