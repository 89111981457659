import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ProseLoader, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Yours sincerely,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

const emailSubjects = [
  `Don't Downgrade Women's Conference`,
]

const content = {
  // Page
  title: `Labour Women Won't Be Sidelined`,
  intro: `Each year, Labour's annual Women's Conference brings together politicians and activists from across the country to participate in policy making and democratic debate, and listen to speeches from leading figures within the Party.

However, this year's Women's Conference will take place **over just one day in Liverpool, with no stalls or separate fringe meetings.** This is a clear breach of the Labour Party rulebook, following the Democracy Review, which introduced a 2 day standalone conference in 2019, and continues to have the support of both the National Women's Committee and Women's Conference Arrangements Committee.

The Women's Conference should provide a space where the voices of all Labour women are heard. That's why Momentum are working with the **Campaign for Labour Party Democracy and Labour Women Leading to urge the party to recommit to a standalone two-day Women's Conference,** focusing on democratic debate and grassroots networking, more women's branches in CLPs, and strengthening the voice of Labour women nationally.

Do you believe that Labour should bring back the two-day Women's Conference? **Use our lobbying tool to put pressure on Keir Starmer** to make sure all Labour women's voices are heard.

You can also sign up to our [Don't Downgrade Women's Conference webinar on Friday 29 September at 6:30pm](https://momentum.nationbuilder.com/womensconferencewebinar), chaired by Momentum Co-Chair Hilary Schan and jointly organised with CLPD and LWL.`,

  // Email
  recipients: ['keir.starmer.mp@parliament.uk'],
  subject: emailSubjects,
  header: (vs: Values) => `Dear Keir Starmer,`,
  body: `I am concerned that this year's Labour Women's Conference will take place on just one day in October, with no separate fringe meetings or stalls.

Despite being in breach of the party rulebook, and opposition from the National Women's Committee and Women's Conference Arrangements Committee, it's disappointing that Labour has decided to ignore women members by not going ahead with the two-day event, as was agreed in the Democracy Review of 2018.

Women's Conference provides the perfect opportunity for our activists and members to network at the grassroots and participate in democratic debate. The two-day Women's Conference in Telford in 2019 proved to be a vibrant success withover 1300 delegates takingpart and two motions sent to Labour Party Annual Conference.

We hope you will agree that women's voices are vital in debating and deciding policy in the run up to the General Election and that's why we must expand women's influence in the party by advocating for more women's branches in CLPs, and by strengthening the voices of the National Women's Committee and the Women's Conference Arrangements Committee.

That's why I am urging you to bring back a stand-alone two-day Women's Conference in 2024, so all Labour women's voices are heard.`,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just demanded that Labour bring back a stand-alone Women's Conference! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/lobby_womens_conference.png',

  // NB
  eventCode: '2023-09 Lobby Women\'s Conference',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/womens-conference?lobby`,
  description: `Use this to demand that Labour bring back a stand-alone Women's Conference!`,
  img: `${PUBLIC_URL}/img/lobby_womens_conference.png`,
  imgAlt: `Labour must bring back a stand-alone two-day Women's Conference`,
}

const WomensConference = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <ProseLoader body={content.body} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>

            <div className="bg-neutral-200">
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" md="6">
                    <h2>Edit your email</h2>

                    <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                    <EmailBodyField property="body" errors={errors} />

                    <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <LobbyNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName="Keir Starmer"
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default WomensConference
