import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ConstituencyFinder, FormAnchor, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

import MPs from '../MPs-2024.json'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)

// Email bodies
// This one is for MPs who've not spoken up yet
const emailForMP = `As a concerned voter, it's heartbreaking to hear that 10,000 more children have been plunged into poverty since the General Election.

You can take immediate action as a Labour MP to address the scourge of child poverty by urging the Government to scrap the heinous two-child benefit cap. This can be easily funded with wealth taxes on the super-rich and would lift hundreds of thousands of kids out of poverty.

I'm urging you to take real action against child poverty. No child should be left behind.`

// And this one is for people who's MP has spoken up, or isn't a Labour MP
const emailForKeith = `As a concerned voter, it's heartbreaking to hear that 10,000 more children have been plunged into poverty since the General Election.

You must take immediate action as Prime Minister to address the scourge of child poverty by scrapping the heinous two-child benefit cap. This can be easily funded with wealth taxes on the super-rich and would lift hundreds of thousands of kids out of poverty.

I'm urging you to take real action against child poverty. No child should be left behind.`

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Kind regards,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

// If the MP is in the Labour Party (or "Labour (Co-op)") we should email them
// Otherwise email Keith directly
const shouldEmail = (MP: any) => (
  MP && (MP['Party'] === 'Labour' || MP['Party'] === 'Labour (Co-op)')
)

// Returns the relevant email body for the given MP
const getEmailBodyForMP = (MP: any) => {
  if (shouldEmail(MP)) {
    return emailForMP
  } else {
    return emailForKeith
  }
}

const subjects = [
  'No Child Left Behind',
  'Scrap the Cap!',
]

const content = {
  // Page
  title: `Tell Labour: Scrap the Cap!`,
  intro: `Child poverty soared under the Tories. But since the General Election, a further 10,000 children have been plunged into poverty.

No child should live in poverty in the sixth richest country in the world. We're calling on the Labour Government to take action to end child poverty by scrapping the two-child benefit cap.

This can be easily funded through wealth taxes on the super-rich, with billions left over to invest in our public services.

**Agree?** Use our lobbying tool below to email your Labour MP urging them to keep up the pressure. If you do not have a Labour MP, you can email Keir Starmer instead.`,

  // Email
  recipients: (vs: Values) => shouldEmail(vs.MP) ? vs.MP.Email : 'keir.starmer.mp@parliament.uk',
  subject: (vs: Values) => subjects,
  header: (vs: Values) => `Dear ${vs.MP && shouldEmail(vs.MP) ? vs.MP['Name (Display as)'] : 'Keir Starmer'},`,
  body: getEmailBodyForMP,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just demanded that Labour take action on child poverty! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/child_poverty_banner.png',

  // NB
  eventCode: '2024-10 Lobby Child Poverty',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/child-poverty?lobby`,
  description: `Use this to demand that Labour take action on child poverty!`,
  img: `${PUBLIC_URL}/img/child_poverty_embed.png`,
  imgAlt: `Tell Labour: Scrap the Cap!`,
}

const messageText = (MP: any) => {
  if (shouldEmail(MP)) {
    return `Your Labour MP is ${MP?.['Name (Display as)']}. Use our lobbying tool below to demand they take action on child poverty.`
  } else {
    return `You don't have a Labour MP. Use our lobbying tool below to demand that Keir Starmer takes action on child poverty.`
  }
}

const ChildPoverty = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SingleLineField
                    property="postcode"
                    label="First, enter your UK postcode"
                    errors={errors}
                  />
                  <ConstituencyFinder mps={MPs} body={content.body} newBoundaries />

                  { values.constituency && (
                    <>
                      <div style={{marginBottom: '1rem'}}>
                        { messageText(values?.MP) }
                      </div>
                      <SingleLineField
                        property="givenName"
                        label="First name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="familyName"
                        label="Last name"
                        errors={errors}
                      />
                      <SingleLineField
                        property="email"
                        label="Email address"
                        errors={errors}
                        type="email"
                        required
                      />
                      <SingleLineField
                        property="mobile"
                        label="Mobile phone"
                        errors={errors}
                      />
                      <OptionField
                        property="labour"
                        label="Are you a Labour Party member?"
                        options={['--', 'Yes', 'No']}
                        errors={errors}
                      />
                    </>
                  )}
                  </Col>
                <FormAnchor />
              </Row>
            </Container>

            { values.constituency && (
              <>
                <div className="bg-neutral-200">
                  <Container>
                    <Row className="justify-content-center">
                      <Col sm="12" md="6">
                        <h2>Edit your email</h2>

                        <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                        <EmailBodyField property="body" errors={errors} />

                        <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <Container>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      <LobbyNotice
                        errors={errors}
                        submissionStatus={submissionStatus}
                      />

                      <OptInField property="optIn" errors={errors} />

                      <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                    </Col>
                  </Row>
                </Container>

                <Container style={{marginTop: 0, marginBottom: 15}}>
                  <Row className="justify-content-center">
                    <Col sm="12" md="6">
                      * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                    </Col>
                  </Row>
                </Container>
              </>
            ) }

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName={
                values.MP && shouldEmail(values.MP) ? (values.MP as any)['Name (Display as)'] : 'Keir Starmer'
              }
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default ChildPoverty
