import React, { useState } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Button, Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'

import { OptionField, SingleLineField, SubmitForm } from '../../components/FormGroup/FormGroup'
import { Imprint } from '../../components/Common/CommonComponents'

import unions from './unions.json'

const PUBLIC_URL = process.env.PUBLIC_URL || "https://community.peoplesmomentum.com"

interface Values {
  email: string
  union: string
  birthdate: string
  labour?: "--" | "Yes" | "No"
  student?: "--" | "Yes" | "No"
  gender?: '--' | 'Female' | 'Male' | 'Non-Binary/Other'
  bame?: "--" | "Yes" | "No"
  disabled?: "--" | "Yes" | "No"
}

enum SubmissionStatus {
  Unsubmitted,
  Submitting,
  Success,
  Failure,
}

// Stuff that appears on social media previews
const meta = {
  title: 'Momentum Equalities Form',
  url: `${PUBLIC_URL}/equalities`,
  description: 'Equalities monitoring form',
  img: `${PUBLIC_URL}/img/2021-05-28_Banner_Community.png`,
  imgAlt: 'Momentum Community',
}

// Used for our NB tags and logging
const eventCode = 'Equalities Form'

// Components

// Header content
const Head = () => (
  <Helmet>
    <title>{meta.title}</title>
    <meta name="title" content={meta.title}/>
    <meta name="description" content={meta.description}/>

    <meta property="og:type" content="website"/>
    <meta property="og:url" content={meta.url} />
    <meta property="og:title" content={meta.title} />
    <meta property="og:description" content={meta.description} />
    <meta property="og:image" content={meta.img} />
    <meta property="og:image:alt" content={meta.imgAlt}/>

    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:url" content={meta.url}/>
    <meta property="twitter:title" content={meta.title} />
    <meta property="twitter:description" content={meta.description} />
    <meta property="twitter:image" content={meta.img}/>
    <meta property="twitter:image:alt" content={meta.imgAlt}/>
  </Helmet>
)

// Send telemetry data to plausible
// NOTE: following the extensive form changes, this probably needs significant adjustment
// TODO: ...do we even want to use this on this page?
const postTelemetry = (vs: Values) => {
  const decodeTriValue = (x: string|undefined) => x === undefined || x === "--" ? "unknown" : x
  window.plausible && window.plausible(
    'lobby',
    {
      props: {
        event: eventCode,
        'is labour member': decodeTriValue(vs.labour),
        'is ethnic minority': decodeTriValue(vs.bame),
        'union': vs.union,
      }
    }
  )
}

// Convert "Yes"/"No"/"--" values to true/false/undefined
const yesNoToBool = (value: "--" | "Yes" | "No" | undefined) => {
  return value === undefined ? undefined : { Yes: true, No: false }[value]
}

// Convert "Female" / "Male" / "Non-Binary / Other" / "--" values to M / F / O / undefined, respectively
const genderToMFO = (value: '--' | 'Female' | 'Male' | 'Non-Binary/Other' | undefined) => {
  return value === undefined ? undefined : { Female: 'F', Male: 'M', 'Non-Binary/Other': 'O' }[value]
}

// Convert form values to the format the backend wants and send it over,
// then post telemetry if successful, and set the submission status
const handleSubmit = (setSubmissionStatus) => async (values: Values) => {
  // I know Formik has its isSubmitting prop but this way I can just use a switch statement
  setSubmissionStatus(SubmissionStatus.Submitting)
  const convertedValues = { update: {
    email: values.email,
    union: values.union,
    birthdate: values.birthdate,
    labour: yesNoToBool(values.labour),
    student: yesNoToBool(values.student),
    gender: genderToMFO(values.gender),
    bame: yesNoToBool(values.bame),
    disabled: yesNoToBool(values.disabled),
  } }

  // Send to backend
  const res = await SubmitForm('/update', convertedValues)

  if (res?.ok) {
    postTelemetry(values)
    setSubmissionStatus(SubmissionStatus.Success)
  } else {
    setSubmissionStatus(SubmissionStatus.Failure)
  }
}

// Determine what the submit button should display
const buttonText = (submissionStatus) => {
  switch(submissionStatus) {
    case SubmissionStatus.Submitting:
      return 'Sending...'
    case SubmissionStatus.Success:
      return 'Sent!'
    default:
      return 'Send'
  }
}

const Equalities = () => {
  const { email } = useParams()

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  return (
    <>
      <Head />

      <Formik
        initialValues={{
          email: email ? decodeURIComponent(email) : '',
          union: '',
          birthdate: '',
          labour: undefined,
          student: undefined,
          gender: undefined,
          bame: undefined,
          disabled: undefined,
        }}
        onSubmit={handleSubmit(setSubmissionStatus)}
      >
        {({ values, isSubmitting, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <h1>Momentum Equalities Form</h1>

                  <SingleLineField
                    property="email"
                    label="Email"
                    type="email"
                    errors={errors}
                  />
                  <SingleLineField
                    property="birthdate"
                    label="Date of Birth"
                    type="date"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={["--", "Yes", "No"]}
                    errors={errors}
                  />
                  <OptionField
                    property="student"
                    label="Are you a student?"
                    options={["--", "Yes", "No"]}
                    errors={errors}
                  />
                  <OptionField
                    property="gender"
                    label="Which gender do you identify as?"
                    options={['--', 'Female', 'Male', 'Non-Binary/Other']}
                    errors={errors}
                  />
                  <OptionField
                    property="bame"
                    label="Do you consider yourself to be an ethnic minority?"
                    options={["--", "Yes", "No"]}
                    errors={errors}
                  />
                  <OptionField
                    property="disabled"
                    label="Do you consider yourself to have a disability?"
                    options={["--", "Yes", "No", "Prefer not to say"]}
                    errors={errors}
                  />
                  <OptionField
                    property="union"
                    label="Which union are you in?"
                    options={["--", ...unions]}
                    errors={errors}
                  />
                  <Button
                    id="sendLobbyEmail"
                    className="btn btn-outline-primary btn-block"
                    type="submit"
                    disabled={isSubmitting || submissionStatus === SubmissionStatus.Success}
                    errors={errors}
                  >
                    { buttonText(submissionStatus) }
                  </Button>
                </Col>
              </Row>
            </Container>
            { submissionStatus === SubmissionStatus.Success && (
              <Redirect
                push
                to="/equalities/thanks"
              />
            ) }
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default Equalities
