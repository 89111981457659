import React, {useState} from 'react'
import { Button, Container, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'

import { Imprint } from '../../components/Common/CommonComponents'

const PUBLIC_URL = process.env.PUBLIC_URL || "forms.peoplesmomentum.com"

class Verb {
  verb: string;
  conjunction: string;
  constructor(verb: string, conjunction: string) {
    this.verb = verb;
    this.conjunction = conjunction;
  }
}

function Motion() {

  const [motion, setMotion] = useState("")

  // TODO make these external vars
  const verbs: Array<Verb> = [
    new Verb("urges", "to"),
    new Verb("calls for", "to"),
    new Verb("calls on", "to"),
    new Verb("asks", "to"),
    new Verb("politely urges", "to"),
    new Verb("humbly begs", "to"),
    new Verb("hesitantly requests", "to"),
    new Verb("nervously requests", "to"),
    new Verb("softly asks", "to"),
    new Verb("delicately requests", "to"),
    new Verb("humbly entreats", "to"),
    new Verb("exhorts", "to"),
    new Verb("implores", "to"),
    new Verb("believes", "should"),
    new Verb("recognises", "should"),
    new Verb("welcomes that", ""),
    new Verb("agrees that", "should"),
    new Verb("affirms", "should"),
    new Verb("reaffirms", "should"),
    new Verb("commends", "to"),
    new Verb("resolves that", "should"),
    new Verb("instructs", "to"),
    new Verb("acknowledges that", "should"),
    new Verb("expresses that", "should"),
    new Verb("notes that", "should"),
    new Verb("notes with concern", "should"),
    new Verb("agrees that", "should"),
    new Verb("stands for", "to")
  ]

  const nouns = ["the Labour Party", "the Parliamentary Labour Party", "the Leader of the Opposition, Keir Starmer,", "Keith Starmer QC", "General Secretary David Evans", "the Leadership of our Party"]

  const actions = ["hire a graphic designer", "stop it", "oppose the Conservative Party", "come up with some better ideas", "confirm that these days you get arrested just for saying you’re English", "call for a second referendum", "be twenty points ahead in the polls", "explain why people should vote Labour", "comment on the weather",  "be more forensic", "stop politicising politics", "agree to disagree", "get back on the #labourdoorstep", "triangulate, somehow", "move forward, not backward; upward, not forward; and always twirl towards freedom", "remember the ten pledges", "nip out to pick up the dry cleaning", "write a strongly worded letter about my neighbour’s hedge", "participate in Movember", "give yourselves a big hand [holds up large foam hand]", "take out the bins", "create an official Labour Party jam", "give yourselves a pat on the back", "turn that light out (What is this, the Blackpool illuminations?)", "answer yes or no", "get us two pints of Peroni and a packet of prawn cocktail? Cheers", "repeatedly abstain", "speak for a long time without saying much at all"]

  const pickRandom = (list: Array<any>) : any => {
    return list[Math.floor(Math.random() * list.length)]
  }

  const generate = () => {
    const verb = pickRandom(verbs)
    const conj = verb['conjunction'].length > 0 ? `${verb['conjunction']} ` : ""
    setMotion(`This CLP ${verb['verb']} ${pickRandom(nouns)} ${conj}${pickRandom(actions)}.`)
  }

  const meta_title = "Make your own CLP motion! ✍️🤔"
  const meta_description = "The Labour leadership say you can't talk about Corbyn, so what can you talk about?"
  const meta_url = `https://${PUBLIC_URL}/motion/`
  const meta_img = `${PUBLIC_URL}/img/2020-11-27_MetaImg_Motion.png`

  const encoded_url = encodeURIComponent(meta_url)
  const encoded_title = encodeURIComponent(meta_title)
  const encoded_description = encodeURIComponent(meta_description)

  return (
    <>
      <Helmet>
        <title>{meta_title}</title>
        <meta name="title" content={meta_title} />
        <meta name="description" content={meta_description} />

        <meta property="og:url" content={meta_url} />
        <meta property="og:title" content={meta_title} />
        <meta property="og:description" content={meta_description} />
        <meta property="og:image" content={meta_img} />

        <meta property="twitter:url" content={meta_url} />
        <meta property="twitter:title" content={meta_title} />
        <meta property="twitter:description" content={meta_description} />
        <meta property="twitter:image" content={meta_img} />
      </Helmet>

      <Container style={{maxWidth: "700px"}} className="px-4">
        <h1 className="my-5" style={{fontSize: "3em"}}>
          <span style={{ textDecoration: "underline" }}>Make your own Constituency Labour Party motion!</span>
          {' '}<span role="img" aria-label="writing hand">✍️</span>
          {' '}<span role="img" aria-label="thinking face">🤔</span>
        </h1>
        <p className="py-4 m-0">
          <strong>Recommended by</strong>{' '}
          <img style={{ maxHeight: "1em" }} alt="logo" src={`/img/2020-11-26_Logo_Full-Red-Small.png`} />
        </p>
      </Container>

      <Container
        className="p-4"
        style={{ background: "#f4eff0" }}
        fluid
      >
        <Container style={{ maxWidth: "700px" }}>
          <h2>
            The Labour Party leadership say you can't talk about Jeremy Corbyn, but what{' '}
            <strong style={{textDecoration: "underline"}}>can</strong>{' '}
            you talk about? Find out here.
          </h2>
        </Container>
      </Container>

      <Container style={{ maxWidth: "700px" }} className="my-5">
        <Button className="btn btn-outline-primary btn-block my-4" onClick={generate}>
          {motion ? "Find another motion" : "Find a motion"}
        </Button>

        {
          motion && (
            <>
              <h2>Try this:</h2>
              <h1 style={{ color: "#FF0F80"}}>“{motion}”</h1>
              <h4>
              </h4>
            </>
          )
        }
      </Container>

      {
        motion && (
          <>
            <Container
              className="p-4"
              style={{ background: "#f4eff0" }}
              fluid
            >
              <Container style={{ maxWidth: "700px" }}>
                <h2>This attempt to shut down free speech is a joke. But we need a serious response. </h2>
                <h2>
                  Momentum is defending the role of members and democracy in Labour.{' '}
                  <a
                    href="https://join.peoplesmomentum.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{fontWeight: 900, textDecoration: "underline"}}
                  >
                    Join now, and help us shape the future of the party.
                  </a>
                </h2>
              </Container>
            </Container>

            <Container style={{ maxWidth: "700px" }}>
              <Row>
                <Col xs="12" sm="4" className="pb-2">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encoded_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary btn-block">
                    Share on Facebook
                  </a>
                </Col>
                <Col xs="12" sm="4" className="pb-2">
                  <a
                    href={`https://twitter.com/intent/tweet?url=${encoded_url}&text=${encoded_title}${encodeURIComponent(`\n\n`)}${encodeURIComponent(`Such as:\n\n“${motion}”`)}${encodeURIComponent(`\n\n`)}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary btn-block"
                  >
                    Share on Twitter
                  </a>
                </Col>
                <Col xs="12" sm="4" className="pb-2">
                  <a
                    href={`https://api.whatsapp.com/send?text=*${encoded_title}*${encodeURIComponent(`\n\n`)}${encoded_description}${encodeURIComponent(` Here's mine:\n\n*“${motion}”*`)}${encodeURIComponent(`\n\nFind yours at `)}${encoded_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-primary btn-block"
                  >
                    Share on WhatsApp
                  </a>
                </Col>
              </Row>
            </Container>
          </>
        )
      }
      <Imprint />
    </> 
  )
}

export default Motion
