import React from 'react'
import { Container, Row, Col } from 'reactstrap'

import { FacebookShareLink, TwitterShareLink, Imprint } from '../../../components/Common/CommonComponents'

const ThankYou = ({ location }) => {
  const { state } = location
  const { givenName, shareCopy, shareUrl } = state

  let thankYouMessage = 'Thanks'
  if (givenName) thankYouMessage += ` ${givenName}`
  thankYouMessage += `! Your signature has been added to the petition!`

  const twitterUrl =
    `https://twitter.com/intent/tweet?url=${shareUrl}&text=${encodeURIComponent(shareCopy)}`
  // NOTE: Facebook will cry if the URL is localhost so this is broken in testing,
  // but I've tested with a real URL and it works fine, so should be ok in production
  const facebookUrl =
    `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}&quote=${encodeURIComponent(shareCopy)}`

  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" md="6">
            <h1>Thank You!</h1>
            <p>{thankYouMessage}</p>
            <h2>Support the Cause</h2>
            <p>
              Want to do more? You can help spread the word by sharing on social media, or support our work on this and future campaigns by donating to Momentum!
            </p>
            <Row>
              <TwitterShareLink url={twitterUrl} />
              <FacebookShareLink url={facebookUrl} />
            </Row>
            <a
              href="https://momentum.nationbuilder.com/momentum_donation"
              className="btn btn-outline-primary"
              target="_blank"
              rel="noreferrer"
            >
              Donate
            </a>
          </Col>
        </Row>
      </Container>
      <Imprint/>
    </>
  )
}

export default ThankYou
