import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Container, Col, Row } from 'reactstrap'

import { MediaType, SubmissionStatus } from './CommonUtils'
import { CheckBoxField } from '../FormGroup/FormGroup'
import { ReactComponent as TwitterLogo } from './logo-twitter.svg'
import { ReactComponent as FacebookLogo } from './logo-facebook.svg'

interface MediaProps {
  mediaType: MediaType,
  urlOrSlug: string,
  alt?: string,
}

// Header content
const Head = ({ meta }) => (
  <Helmet>
    <title>{meta.title}</title>
    <meta name="title" content={meta.title}/>
    <meta name="description" content={meta.description}/>

    <meta property="og:type" content="website"/>
    <meta property="og:url" content={meta.url} />
    <meta property="og:title" content={meta.title} />
    <meta property="og:description" content={meta.description} />
    <meta property="og:image" content={meta.img} />
    <meta property="og:image:alt" content={meta.imgAlt}/>

    <meta property="twitter:card" content="summary_large_image"/>
    <meta property="twitter:url" content={meta.url}/>
    <meta property="twitter:title" content={meta.title} />
    <meta property="twitter:description" content={meta.description} />
    <meta property="twitter:image" content={meta.img}/>
    <meta property="twitter:image:alt" content={meta.imgAlt}/>
  </Helmet>
)

// The video that appears at the top of the page
// NOTE: Presumably prose.youtube and prose.vimeo are mutually exclusive, so this could probably
// be handled better (eg. using props instead of directly using prose object, etc)
const Media: React.SFC<MediaProps> = (props) => {
  // react-snap hates this iframe for some reason.
  if (navigator.userAgent === 'ReactSnap') return null
  if (props.mediaType === MediaType.Vimeo) {
    return (
      <Row className="no-gutters justify-content-center mb-3">
        <div style={{position: 'relative', width: '100%', height: 600, maxHeight: '100vw'}}>
          <iframe
            title="vimeo-player"
            src={props.urlOrSlug}
            allow="clipboard-write; picture-in-picture"
            frameBorder="0"
            allowFullScreen
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
          />
        </div>
      </Row>
    )
  }
  if (props.mediaType === MediaType.YouTube) {
    return (
      // Styles from https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
      <Row className="no-gutters justify-content-center my-3 container-lg mx-auto" style={{maxWidth: 600}}>
        <div style={{position: 'relative', width: '100%', height: 0, paddingBottom: '56.25%'}}>
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${props.urlOrSlug}`}
            title="YouTube video player"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture"
            allowFullScreen
            style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
          />
        </div>
      </Row>
    )
  }
  return (
    <Row className="no-gutters justify-content-center my-3 container-lg mx-auto" style={{maxWidth: 600}}>
      <img src={props.urlOrSlug} alt={props.alt} style={{width: '100%'}} />
    </Row>
  )
}

// Generic legal copy for opt in checkbox label
const legalCopy = `I agree to Momentum using the information I provide to keep
me updated via email and phone about Momentum's campaigns and opportunities
to get involved, both locally and on a national level, in line with their
[privacy policy](https://peoplesmomentum.com/privacy-policy/)`

// Opt in checkbox
const OptInField = ({ property, errors }) => (
  <CheckBoxField
    property={property}
    label={legalCopy}
    errors={errors}
  />
)

// Redirects to given page on successful submission
// TODO: Should this handle the shareUrl?
const RedirectOnSubmit = ({ submissionStatus, path, state }) => {
  if (submissionStatus !== SubmissionStatus.Success) return null
  return (
    <Redirect
      push
      to={{
        pathname: path,
        state: {
          shareUrl: window.location.href,
          ...state
        },
      }}
    />
  )
}

// Share link for Facebook
const FacebookShareLink = ({url}) => (
  <Col sm="6" md="6">
    <a
      href={url}
      className="btn btn-outline-primary"
      target="_blank"
      rel="noreferrer"
      style={{ flexDirection: 'row', alignItems: 'center', paddingRight: '1.8rem' }}
    >
      <FacebookLogo style={{ maxHeight: '1.4rem', marginRight: '.4rem' }} />
      Share on Facebook
    </a>
  </Col>
)

// Share link for Twitter. I'm not calling it X.
const TwitterShareLink = ({url}) => (
  <Col sm="6" md="6">
    <a
      href={url}
      className="btn btn-outline-primary"
      target="_blank"
      rel="noreferrer"
      style={{ flexDirection: 'row', alignItems: 'center', paddingRight: '1.8rem' }}
    >
      <TwitterLogo style={{ maxHeight: '1.4rem', marginRight: '.4rem' }} />
      Share on Twitter
    </a>
  </Col>
)

// Digital imprint - put this at the bottom of every page
const Imprint = () => {
  const [imprint, setImprint] = useState('...')

  useEffect(() => {
    const getImprint = async () => {
      try {
        const response = await fetch('/imprint')
        const text = await response.text()
        setImprint(text)
      } catch (e) {
        console.log('Error:', e)
      }
    }
    getImprint()
  }, [])

  return (
    <Container className="pb-3" style={{ fontSize: 12, fontWeight: 500 }}>
      <Row>
        <Col md={{ size: 6, offset: 3 }}>
          { imprint }
        </Col>
      </Row>
    </Container>
  )
}

export { Head, Media, OptInField, RedirectOnSubmit, FacebookShareLink, TwitterShareLink, Imprint }
