import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ProseLoader, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Yours in solidarity,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

const emailSubjects = [
  'Clean up politics',
  'Ban dark money',
  'No shell companies in Labour',
  'Return dark money donations now'
]

const content = {
  // Page
  title: `Labour: Ban Dark Money`,
  intro: `A new Sky investigation has revealed that **MPs are making millions on the side from dark money donors**. Predictably, the money-grubbing Tories top the list - but disturbingly, **Labour MPs feature prominently too**.

Shadow cabinet members like **Wes Streeting and Yvette Cooper have [taken hundreds of thousands of pounds of dark money](https://news.sky.com/story/westminster-accounts-mps-challenged-to-provide-more-transparency-over-the-source-of-donations-12781152)** from the shell company MPM Connect.

With the Tories mired in sleaze, **Labour needs to lead from the front to restore trust in politics**.

**Use our lobbying tool now to write to Keir Starmer and Wes Streeting** to demand that:
- Labour recommits to the pledges made in the 2019 manifesto to clean up politics - including banning the funnelling of dark money through shell companies.
- Labour MPs in receipt of funding from dark money groups should return the donations immediately.`,

  // Email
  recipients: ['keir.starmer.mp@parliament.uk', 'wes.streeting.mp@parliament.uk'],
  subject: emailSubjects,
  header: (vs: Values) => `To Keir Starmer and Wes Streeting,`,
  body: `In Sky's new Westminster Accounts, which shines a light on MPs' funding, it was unsurprising to see the Tories mired in dark money, making millions on the side. We've all come to expect Tory sleaze, but on this issue, Labour needs to lead from the front if we're to restore trust in politics. That's why I was so disappointed to see a number of Labour MPs on the list too.

In the 2019 manifesto, the Labour Party committed to changing how politics is funded, banning donations from tax avoiders and tax evaders, and closing loopholes that allow the use of shell companies to funnel dark money into politics.

But now, it's been revealed that shell company MPM Connect has funnelled £345,000 of dark money into funding the operations of Yvette Cooper, Wes Streeting and Dan Jarvis. This sets a concerning precedent for the Labour Party, with corporate interests put before the public good.

I would like you to confirm to me, yes or no, whether you will be banning dark money funnelled through shell companies in the Labour Party? And, will the MPs revealed to have accepted dark money be returning their donations?

I look forward to receiving your response.`,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just demanded that Labour ban MPs from accepting dark money! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/dark_money.png',

  // NB
  eventCode: '2023-01 Lobby Dark Money',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/dark-money?lobby`,
  description: `Use this to demand Labour bans MPs from accepting dark money!`,
  img: `${PUBLIC_URL}/img/dark_money.png`,
  imgAlt: `Labour must ban dark money donations`,
}

const DarkMoney = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <ProseLoader body={content.body} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>

            <div className="bg-neutral-200">
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" md="6">
                    <h2>Edit your email</h2>

                    <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                    <EmailBodyField property="body" errors={errors} />

                    <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <LobbyNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName="Keir Starmer and Wes Streeting"
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default DarkMoney
