import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values } from '../../../components/Common/CommonUtils'
import {
  ThanksPageRedirect, PetitionSubmitButton, PetitionNotice, SignatureCount
} from '../Common/PetitionComponents'
import { handleSubmit } from '../Common/PetitionUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

const content = {
  // Page
  title: `Tell Labour: Reverse the removal of Winter Fuel Payment from 10 million pensioners`,
  intro: `The Government's decision to limit the Winter Fuel Payment (WFP) to those who claim Pension Credit will put millions of low&#8209;income pensioners at risk of fuel poverty. The change has been made without an Impact Assessment and is being made in spite of a planned 10% increase in energy prices. It will require a divisive and unpopular means&#8209;test and is opposed by policy experts & organisations representing pensioners.

The Government's claims that this was necessary to prevent a "run on the pound" do not stand up to minimal scrutiny. Ensuring citizens have the means to keep warm during Winter is an absolute minimum expectation of any government let alone a Labour one. We call on the Government to reverse this unjust, unnecessary and divisive decision. Sign the petition!`,

  // Share
  share: (vs: Values) => `I just demanded that Labour reverse their decision to deny Winter Fuel Payments to millions of pensioners! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/fuel_cuts_banner.png',

  // NB
  eventCode: '2024-09 Petition Fuel Cuts',

  // Petition ID
  petitionId: 8,
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/fuel-cuts?lobby`,
  description: `Sign our petition to demand that Labour reverse their decision to deny Winter Fuel Payments to millions of pensioners!`,
  img: `${PUBLIC_URL}/img/fuel_cuts_banner.png`,
  imgAlt: `Tell Labour: Reverse the removal of Winter Fuel Payment from 10 million pensioners`,
}

const FuelCuts = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SignatureCount petitionId={content.petitionId} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <PetitionNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <PetitionSubmitButton submissionStatus={submissionStatus} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default FuelCuts
