import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'reactstrap'
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown'
import Select from 'react-select';

import { Imprint } from '../../components/Common/CommonComponents'

import constituencyStats from './constituencyStats.json'

import './NotLikeUs.css'

import _ from 'lodash'

const PUBLIC_URL = process.env.PUBLIC_URL || "https://forms.peoplesmomentum.com"

const prose = {
  title: `What does Boris know about your home town?`,
  intro: `The Tory Government talks about levelling up the so-called Red Wall. But what do Boris and his cronies know about your hometown? They’ve probably never been, and neither have half the MPs before they were elected there. We’ve had a decade of Tory rule and things have got worse. We’ve not been left behind up here, we’ve been held back, by a political class too focused on the City and the bosses.

  They aren’t going to fix things, but we can. We have to get active in our communities and campaign for that change, building a movement that can truly transform the Labour Party and elect a socialist government.

  That means getting out and talking to people in your area, and convincing them that real change is necessary and possible.

  So if you’re living in one of the 48 ‘Red Wall’ constituencies that Labour recently lost, here’s a few statistics to help you on your way, and to help Boris on his way out.`,
  outro: `Despite what the Tories have done to our hometowns, we love living here and we love the people here. But the only way we’re going to change things is by getting organised, in our workplaces and inside the Labour Party, fighting to build a Party that truly stands up for working people.

  We have to join up with people in every corner of the country who’ve been shafted by the Tories and a system that only works for the wealthy few. And whether it’s a minimum wage warehouse worker who can’t even take a break on shift, someone on the dole and demonised by the papers, or a person of colour suffering racial discrimination who's told by the Government that racism doesn’t exist - there’s millions of us.`,
  sources: {
    num_children_in_poverty: '[Local indicators of child poverty after housing costs, 2019/20, _End Child Poverty_](http://www.endchildpoverty.org.uk/local-child-poverty-data-2014-15-2019-20/)',
    median_pay_per_week: '[_NOMIS (ONS labour market statistics)_](https://www.nomisweb.co.uk/reports/lmp/wpca/contents.aspx), Gross Weekly Pay for all full-time workers, 2019',
    social_mobility_rank: '[Social Mobility Index by Constituency, _House of Commons Library_](https://commonslibrary.parliament.uk/research-briefings/cbp-8400/)',
    pct_change_local_gov_spending: '[Local authority data: finances, _House of Commons Library_](https://commonslibrary.parliament.uk/local-authority-data-finances/)',
    pct_nvq4_and_above: '[_NOMIS (ONS labour market statistics)_](https://www.nomisweb.co.uk/reports/lmp/wpca/contents.aspx), Qualifications, Jan 2020 - Dec 2020',
    pct_households_fuel_poor: '[Sub-regional fuel poverty data 2021, _Department for Business, Energy & Industrial Strategy_](https://www.gov.uk/government/statistics/sub-regional-fuel-poverty-data-2021)'
  },
  callToActionTexts: [
    "Join a movement that gives a shit",
    "Want to do something about it?",
    "Join us. Fight for what you believe in!",
  ],
  callToActionLink: "https://momentum.nationbuilder.com/red-wall-microsite-signup",
}

// Stuff that appears on social media previews
const meta = {
  title: prose.title,
  url: `${PUBLIC_URL}/what-does-he-know`,
  description: "They talk about levelling up the Red Wall, but what do Boris and his cronies know about your hometown?",
  img: `/img/boris-cabinet-far.jpg`
}

const NotLikeUs = () => {

  const [constituency, setConstituency] = useState<string|undefined>(undefined)

  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="title" content={meta.title}/>
        <meta name="description" content={meta.description}/>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={meta.url} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:description" content={meta.description} />
        <meta property="og:image" content={meta.img} />

        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={meta.url}/>
        <meta property="twitter:title" content={meta.title} />
        <meta property="twitter:description" content={meta.description} />
        <meta property="twitter:image" content={meta.img}/>
      </Helmet>

      { /* Exciting heading! */ }
      <Container style={{position: 'relative'}}>
        <Container className="popup">
          <div>
            <img src="/img/confused-boris.jpg"/>
          </div>
        </Container>
        <Row className="justify-content-center">
          <Col sm="12" lg="8">
            <h1 className="my-5" style={{fontSize: "3em"}}>
              <span style={{ textDecoration: "underline" }}>
                {prose.title}
              </span>
              <span role="img" aria-label="thinking face">🤔</span>
            </h1>
            <p className="py-4 m-0">
              <strong>By </strong>
              <a href="https://peoplesmomentum.com/">
                <img
                  style={{ maxHeight: "1em" }}
                  alt="momentum"
                  src={`/img/2020-11-26_Logo_Full-Red-Small.png`}
                />
              </a>
              <strong> and </strong>
              <a href="https://noholdingback.org.uk/">
                <img style={{ maxHeight: "1.5em" }} alt="No Holding Back" src={`/img/no-holding-back-logo.png`} />
              </a>
            </p>
          </Col>
        </Row>
      </Container>

      { /* Attention-grabbing contrast zone */ }
      <Container fluid className='p-4' style={{ zIndex: 1, background: '#f4eff0' }}>
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" lg="8">
              <ReactMarkdown>{prose.intro}</ReactMarkdown>
            </Col>
          </Row>
        </Container>
      </Container>

      { /* Simple form */ }
      <Container style={{marginBottom: 15}}>
        <Row className="pt-4 justify-content-center">
          <Col sm="12" lg="8">
            <Select
              placeholder="Find your constituency"
              options={constituencyStats.filter(x => x.is_red_wall).map(x => ({value: x.name, label: x.name}))}
              onChange={x => {setConstituency(x?.value)}}
              styles={{control: (provided, state) => ({...provided, borderWidth: "3px", fontWeight: 700})}}
            />
          </Col>
        </Row>
      </Container>

      {
        constituency && (
          <>
            <Container className="pt-4 pb-4">
              <Row className="justify-content-center">
                <Col sm="12" lg="8">
                  <ConstituencyInfo name={constituency}/>
                </Col>
              </Row>
            </Container>

            <Container fluid className='p-4' style={{ background: '#f4eff0' }}>
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" lg="8">
                    <ReactMarkdown>{prose.outro}</ReactMarkdown>
                    { /* TODO: Decide what the call to action is, probably a ladder
                         would be best. (Comms can make one in NB or Carrd and we can
                         direct to it) */ }
                    <Button
                      className="mt-4 btn btn-outline-primary btn-block"
                      type="submit"
                      href={prose.callToActionLink}
                    >
                      {prose.callToActionTexts[_.random(prose.callToActionTexts.length-1)]}
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Container>
          </>
        )
      }

      <Imprint/>
    </>
  )
}

const cData = (name:string) => constituencyStats.filter(x => x.name === name)[0]

const ConstituencyInfo = (props: any) => {
  const here = cData(props.name)

  const poshplace = cData('Cities of London and Westminster')
  const boris = Object.assign({}, cData('Uxbridge and South Ruislip'))
  boris.name = "Uxbridge (Boris' seat)"
  const ukavg = cData('UK average')

  let comparisons: JSX.Element[] = []

  const source_bit = (key: any) => (
    <ReactMarkdown className='source'>{prose.sources[key]}</ReactMarkdown>
  )

  const medpay_bit = (comparison: any) => (
      <>
        <h1>Median pay per week</h1>
        <p className="mb-2">What could you do with an extra £{_.round(comparison.median_pay_per_week - here.median_pay_per_week)} per week? What could your neighbours do?</p>
        <h3>{here.name} - £{_.round(here.median_pay_per_week, 2)}</h3>
        <h3>{comparison.name} - £{_.round(comparison.median_pay_per_week, 2)}</h3>
        {source_bit('median_pay_per_week')}
      </>
  )

  if ((here.median_pay_per_week - boris.median_pay_per_week) < -30) {
    comparisons.push(medpay_bit(boris))
  } else {
    comparisons.push(medpay_bit(poshplace))
  }

  if (here.pct_nvq4_and_above - boris.pct_nvq4_and_above < 0) {
    comparisons.push(
      <>
        <h1 className="pt-2">Education</h1>
        <p className='mb-2'>Education is a great way to improve your lot! Getting a degree is a one way ticket to a better life, they say.</p>
        <h3>{here.name} - {_.round(here.pct_nvq4_and_above * 100)}% of adults have a certificate of higher education, a degree or an NVQ4</h3>
        <h3>{boris.name} - {_.round(boris.pct_nvq4_and_above * 100)}%</h3>
        <p>But it looks like in {here.name} you have much less of an opportunity to get a higher education than you do in Boris’ seat? If only ten year’s worth of Tory Education Ministers had used their power to do something about it….
        </p>
        {source_bit('pct_nvq4_and_above')}
      </>
    )
  }

  const socmob_bit = (comparison: any) => (
      <>
        <h1 className="pt-2">Social mobility rank</h1>
        <p className="mb-2">But it's okay, it’s not where you start it’s where you end up, right? And everyone can pull themselves up by their bootstraps, even if they didn’t do well in school.</p>
        <h3>{here.name} - {here.social_mobility_rank}</h3>
        <h3>{comparison.name} - {comparison.social_mobility_rank}</h3>
        <p>Oh. Out of 533 constituencies in England, {(here.social_mobility_rank - 1)} are ranked better for social mobility than {here.name}. If you didn’t know, social mobility measures the chances of disadvantaged children doing well later in life, and it turns out the odds aren't even after all. Meritocracy is a myth!</p>
        {source_bit('social_mobility_rank')}
      </>
  )

  if (here.social_mobility_rank > 270) {
    comparisons.push(socmob_bit(boris))
  } else if (here.social_mobility_rank > boris.social_mobility_rank) {
    comparisons.push(socmob_bit(poshplace))
  }

  if (here.pct_change_local_gov_spending - ukavg.pct_change_local_gov_spending < 2) {
    comparisons.push(
      <>
        <h1 className="pt-2">Local Government funding</h1>
        <p className='mb-2'>We all rely on local public services and they hold our communities together, proving that not everything has to be run for profit. That’s probably why the Tory Government has spent the last decade defunding and closing them. <strong>Does this look like levelling up to you?</strong></p>
        <h3>{here.name} - {_.round(-here.pct_change_local_gov_spending)}% reduction in real terms 2015-2019</h3>
        <h3>{ukavg.name} - {_.round(-ukavg.pct_change_local_gov_spending)}% reduction</h3>
        <p>These cuts mean library closures, hospitals turning patients away, buses turning up late, and a worse quality of life for you and your family. That’s the Tory way.</p>
        {source_bit('pct_change_local_gov_spending')}
      </>
    )
  }

  if (here.pct_households_fuel_poor - ukavg.pct_households_fuel_poor > 0) {
    let pct_diff = _.round((here.pct_households_fuel_poor - ukavg.pct_households_fuel_poor) * 100)
    comparisons.push(
      <>
        <h1 className="pt-2">Fuel poverty</h1>
        <p className='mb-2'>Feeling cold in your home last winter? You weren't the only one! {pct_diff} percentage point{pct_diff == 1 ? '' : 's'} more households are fuel poor in {here.name} than the UK average.</p>
        <h3>{here.name} - {_.round(here.pct_households_fuel_poor * 100)}%</h3>
        <h3>{ukavg.name} - {_.round(ukavg.pct_households_fuel_poor * 100)}%</h3>
        {source_bit('pct_households_fuel_poor')}
      </>
    )
  }

  if (here.num_children_in_poverty - ukavg.num_children_in_poverty > 0) {
    comparisons.push(
      <>
        <h1 className="pt-2">Child poverty</h1>
        <p className='mb-2'>In a rich country like ours, child poverty is a moral stain. In {here.name} it's even worse than the average UK constituency. What good is levelling up if thousands of children go to bed hungry?</p>
        <h3>{here.name} - {_.round(here.num_children_in_poverty, 1)}</h3>
        <h3>{ukavg.name} - {_.round(ukavg.num_children_in_poverty, 1)}</h3>
        {source_bit('num_children_in_poverty')}
      </>
    )
  }

  // comparisons = _.shuffle(comparisons)

  return (
    <>
      {comparisons}
    </>
  )
}


export default NotLikeUs
