import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  LobbyNotice, ProseLoader, LobbySubmitButton, EmailBodyField, ThanksPageRedirect
} from '../Common/LobbyComponents'
import { handleSubmit } from '../Common/LobbyUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

// Page content (this could probably be pushed out into another file)

// Generates a footer based on the MP we're emailing and the sender's name
const generateFooter = (vs: Values) => {
  const message = 'Yours sincerely,'
  const senderName = `${vs.givenName} ${vs.familyName}`
  if (senderName.length > 1) {
    return [message, senderName].join('\n\n')
  } else if (vs.labour === 'Yes') {
    return [message, 'A concerned Labour member'].join('\n\n')
  } else {
    return [message, 'A concerned individual'].join('\n\n')
  }
}

const emailSubjects = [
  'Oppose the Anti-BDS Bill',
]

const content = {
  // Page
  title: `Labour: Oppose the Anti-BDS Bill`,
  intro: `**The government has brought its "anti-boycott bill" back to Parliament.** This authoritarian attack on democracy aims to prevent public bodies from making ethical investment decisions, in an attempt **to undermine the international Boycott, Divestments and Sanctions movement against Israel.**

In an act of cowardice, the Labour Leadership whipped Labour MPs **to abstain on the bill over the summer,** helping the bill pass its second reading. Ahead of its crucial third reading, even some Tories have spoken up against it.

The "anti-boycott" bill has been reintroduced while thousands of Palestinians have been killed in Israel's bombing of Gaza. Leading campaign groups, including **human rights organisations like Amnesty International**, and leading trade unions including **UNISON** and **Unite**, believe this bill to be a brazen assault on democracy, on devolution and on Palestinian rights. Shamefully, the bill is returning when the Palestinian people need our solidarity the most.

Agree that it's an immoral piece of legislation that Labour should oppose amid the ongoing bloodshed? **Use our lobbying tool to email Keir Starmer** and pressure the Leadership to find its moral compass and vote against the bill at its upcoming crucial third reading.`,

  // Email
  recipients: ['keir.starmer.mp@parliament.uk'],
  subject: emailSubjects,
  header: (vs: Values) => `Dear Keir Starmer,`,
  body: `I am concerned that Labour may not vote against the Tories' Economic Activity of Public Bodies (Overseas Matters) Bill, which prohibits institutions from making ethical decisions.

This Bill represents a direct attack not just on local government, but on the right and ability of Palestinians to resist illegal occupation and apartheid. It specifically singles out Israel by name, in an attempt to undermine the Boycott, Divestments and Sanctions movement.

I am horrified to see it come to Parliament, even as Israeli strikes rain down in Gaza, killing thousands of innocent Palestinian civilians.

Labour must oppose this anti-democratic bill.

Leading trade unions such as Unite and Unison have condemned the bill, and Labour's own legal opinion found it to be inexcusable and in breach of international law.

Public institutions must have free rein to spend, invest and trade in respect of international law and human rights, and no state in violation of international law should be shielded from accountability.

As a member of the Labour party, I am asking you to whip Labour MPs to oppose this anti-democratic bill at third reading, before it's too late.`,
  footer: generateFooter,

  // Share
  share: (vs: Values) => `I just demanded that Labour oppose the anti-BDS bill! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/lobby_bds.png',

  // NB
  eventCode: '2023-10 Lobby BDS',
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/bds?lobby`,
  description: `Use this to demand that Labour oppose the anti-BDS bill!`,
  img: `${PUBLIC_URL}/img/lobby_bds.png`,
  imgAlt: `Labour must oppose the anti-BDS bill`,
}

const BDS = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <ProseLoader body={content.body} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                </Col>
              </Row>
            </Container>

            <div className="bg-neutral-200">
              <Container>
                <Row className="justify-content-center">
                  <Col sm="12" md="6">
                    <h2>Edit your email</h2>

                    <ReactMarkdown>{content.header(values)}</ReactMarkdown>

                    <EmailBodyField property="body" errors={errors} />

                    <ReactMarkdown>{content.footer(values)}</ReactMarkdown>
                  </Col>
                </Row>
              </Container>
            </div>

            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <LobbyNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <LobbySubmitButton submissionStatus={submissionStatus} errors={errors} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              recipientName="Keir Starmer"
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default BDS
