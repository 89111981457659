import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { Container, Row, Col } from 'reactstrap'

import { OptionField, SingleLineField } from '../../../components/FormGroup/FormGroup'
import { Head, Media, OptInField, Imprint } from '../../../components/Common/CommonComponents'
import { SubmissionStatus, MediaType, Values, postcodeRegex } from '../../../components/Common/CommonUtils'
import {
  ThanksPageRedirect, PetitionSubmitButton, PetitionNotice, SignatureCount
} from '../Common/PetitionComponents'
import { handleSubmit } from '../Common/PetitionUtils'

const PUBLIC_URL = process.env.PUBLIC_URL || 'https://community.peoplesmomentum.com'

const content = {
  // Page
  title: `Labour: Stand Against Racism - Restore the Whip to Diane & Kate`,
  intro: `Diane Abbott is being failed by our political leaders. A top Tory donor incites racist, sexist abuse against her - and after trying to defend him, the Conservatives still won't give the money back. The Speaker failed to let Diane speak in the House of Commons, and the Labour Leadership continues to suspend the party whip from Diane, even as they fundraise off her pain.

While Andy McDonald MP rightly had the whip restored on Wednesday, Kate Osamor also remains suspended on a flimsy pretext, Apsana Begum has spoken of her mistreatment at the hands of the Labour Leadership, and Zarah Sultana reveals mountains of abuse - but gets no public support.

As the Tories desperately whip up racism and Islamophobia, we need the Labour Leadership to support its victims. Sign this petition and tell Keir Starmer to restore the whip to Diane Abbott and Kate Osamor - and stand with victims of racism and abuse.`,

  // Share
  share: (vs: Values) => `I just demanded that Labour restore the whip to Diane Abbott & Kate Osamor! Click here to make your voice heard:`,

  // Header
  headerType: MediaType.Image,
  urlOrSlug: '/img/diane_abbott_banner.jpg',

  // NB
  eventCode: '2024-03 Petition Solidarity Diane Abbott',

  // Petition ID
  petitionId: 5,
}

// Stuff that appears on social media previews
const meta = {
  title: content.title,
  url: `${PUBLIC_URL}/solidarity-diane-abbott?lobby`,
  description: `Sign our petition to demand that Labour restore the whip to Diane Abbott & Kate Osamor!`,
  img: `${PUBLIC_URL}/img/diane_abbott_banner.jpg`,
  imgAlt: `Labour: Stand Against Racism - Restore the Whip to Diane & Kate`,
}

const DianeAbbott = () => {
  // I'll remove this later but I'm paranoid about accidentally sending
  // test emails to an actual MP so I need this for peace of mind,
  console.log(process.env.NODE_ENV)

  const [submissionStatus, setSubmissionStatus] =
    useState<SubmissionStatus>(SubmissionStatus.Unsubmitted)

  const lobbySchema = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email').required(' (Required)'),
    postcode: Yup.string().uppercase().matches(postcodeRegex, 'Please enter a valid UK postcode')
  })

  return (
    <>
      <Head meta={meta} />

      <Media mediaType={content.headerType} urlOrSlug={content.urlOrSlug} alt={meta.imgAlt} />

      <Formik
        initialValues={{
          givenName: '',
          familyName: '',
          email: '',
          mobile: '',
          postcode: '',
          optIn: false,
          labour: undefined,
          constituency: undefined,
          MP: undefined,
          body: '',
        }}
        validationSchema={lobbySchema}
        onSubmit={handleSubmit(setSubmissionStatus, content)}
      >
        {({ values, errors }) => (
          <Form>
            <Container>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  <ReactMarkdown>{content.intro}</ReactMarkdown>

                  <SignatureCount petitionId={content.petitionId} />

                  <SingleLineField
                    property="givenName"
                    label="First name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="familyName"
                    label="Last name"
                    errors={errors}
                  />
                  <SingleLineField
                    property="postcode"
                    label="Postcode"
                    errors={errors}
                  />
                  <SingleLineField
                    property="email"
                    label="Email address"
                    errors={errors}
                    type="email"
                    required
                  />
                  <SingleLineField
                    property="mobile"
                    label="Mobile phone"
                    errors={errors}
                  />
                  <OptionField
                    property="labour"
                    label="Are you a Labour Party member?"
                    options={['--', 'Yes', 'No']}
                    errors={errors}
                  />
                  <PetitionNotice
                    errors={errors}
                    submissionStatus={submissionStatus}
                  />

                  <OptInField property="optIn" errors={errors} />

                  <PetitionSubmitButton submissionStatus={submissionStatus} />
                </Col>
              </Row>
            </Container>

            <Container style={{marginTop: 0, marginBottom: 15}}>
              <Row className="justify-content-center">
                <Col sm="12" md="6">
                  * Have we missed something? Let us know at <a href="mailto:info@peoplesmomentum.com">info@peoplesmomentum.com</a>
                </Col>
              </Row>
            </Container>

            <ThanksPageRedirect
              submissionStatus={submissionStatus}
              senderName={values.givenName}
              copy={content.share(values)}
            />
          </Form>
        )}
      </Formik>
      <Imprint/>
    </>
  )
}

export default DianeAbbott
